import { IFluid } from "src/app/services/interfaces";

export interface ILiquid {
  massFlowRate: TParameter;
  inletTemp: TParameter;
  outletTemp: TParameter;
  maxPressureDrop: TParameter;
  liquid: IFluid & { name: string; selectable: boolean; };
  fluidProps: TFluidSpecialProps;
};
export interface ICondensation {
  massFlowRate: TParameter;
  satTemp: TParameter;
  outletTemp: TParameter;
  satPressure: TParameter;
  liquid: IFluid & { name: string; selectable: boolean; };
  fluidProps: TFluidSpecialProps;
};

export interface IEvaporation {
  massFlowRate: TParameter;
  inletTemp: TParameter;
  satTemp: TParameter;
  satPressure: TParameter;
  liquid: IFluid & { name: string; selectable: boolean; };
  fluidProps: TFluidSpecialProps;
};
export interface IGas {
  massFlowRate: TParameter;
  inletTemp: TParameter;
  outletTemp: TParameter;
  maxPressureDrop: TParameter;
  liquid: IFluid & { name: string; selectable: boolean; };
  fluidProps: TFluidSpecialProps;
};

export type TParameter = {
  value: {
    userUnits: any;
    commonUnits: any;
  };
  entered: boolean;
  calculated: boolean;
  unitSystemId: number;
};

export type TPhysicalUnits = { [key: string]: { unitSign: string; unitTitle: string; addFactor: number; multFactor: number; } };
export type TPhysicalUnitsArray = { [key: string]: { unitSign: string; unitTitle: string; addFactor: number; multFactor: number; unitSystemId: number; }[] };

export type TFluidSpecialProps = {
  [key: string]: {
    dependency1Value: number,
    dependency2Value: any,
    fluidId: number,
    fluidPropValueId: number,
    isActive: string,
    physicalPropertyId: string,
    propertyValue: number
  }[]
}
