<!-- zilo-hints-comp start -->
<div class="zilo-hints-comp" *ngIf="hintMessage">

  <!-- top-panel start -->
  <div class="top-panel">
    <svg class="close" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
      fill="#000" (click)="hintMessage = null">
      <path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" />
    </svg>
  </div>
  <!-- top-panel end -->

  <!-- content start -->
  <div class="content">

    <!-- image-container start -->
    <div class="image-container">
      <img src="../../../assets/images/zilo_hints.gif" alt="zilo_hints.gif">
    </div>
    <!-- image-container start -->

    <!-- hint-container start -->
    <div class="hint-container">
      {{hintMessage}}
    </div>
    <!-- hint-container end -->

  </div>
  <!-- content end -->


</div>
<!-- zilo-hints-comp end -->