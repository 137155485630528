import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-duty-validation',
  templateUrl: './duty-validation.component.html',
  styleUrls: ['./duty-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DutyValidationComponent implements OnInit, OnChanges {

  /** Flag to indicate that some error occured. */
  public validationError: boolean = false;

  /** Message that some liquid not selected */
  public liquidNotSelectedErrorMsg: string = null;
  /** Cannot solve underdetermined system - more parameters should be specified */
  public underdeterminedErrorMoreParams: boolean = false;
  /**Cannot solve underdetermined system - calculated parameters should be on different sides */
  public underdeterminedErrorDiffSides: boolean = false;
  /**Overdetermined system - too many parameters have been specified */
  public overdeterminedError: boolean = false;

  /** Condensation */

  /** Saturation temperature/pressure is not entered */
  public saturationParamsNotEntered: boolean = false;
  /** Outlet temperature is not entered the warm side */
  public outletTempWarmNotEntered: boolean = false;

  /**
   * List of validations errors for `Condensation and Evaporation`.
   */
  public validationsErrorsListOfTemp: string[] = [];

  // Validation errors after calculation
  public warmFluidTempNotInRangeWarning: boolean = false;
  public warmMassFlowRateError: boolean = false;
  public warmMaxPressureDropError: boolean = false;

  public coldMassFlowRateError: boolean = false;
  public coldFluidTempNotInRangeWarning: boolean = false;
  public coldMaxPressureDropError: boolean = false;

  public notAcceptableTemperature: boolean = false;
  public notValidOverdesign: boolean = false;

  @Input()
  public warmAppType: string = null;
  @Input()
  public coldAppType: string = null;
  @Input()
  public parametersEntered: { warm: any; cold: any; } = null;
  @Input()
  public parametersCalculated: { warm: any; cold: any; } = null;
  @Input()
  public heatExchangeRate: { value: { userUnits: any; commonUnits: any; }; entered: boolean; calculated: boolean; } = null;
  @Input()
  public overdesign: number = null;

  @Output()
  public enteredParamsValidated: EventEmitter<{ valid: boolean }> = new EventEmitter();
  @Output()
  public calculatedParamsValidated: EventEmitter<{ valid: boolean }> = new EventEmitter();

  constructor() {
    // empty
  };

  public ngOnInit() { };
  public ngOnChanges(changes: SimpleChanges): void {

    if ((changes.parametersEntered && !changes.parametersEntered.firstChange) || (changes.heatExchangeRate && !changes.heatExchangeRate.firstChange)) {
      this.validateEnteredParams();
    };
    if (changes.parametersCalculated && !changes.parametersCalculated.firstChange) {
      this.validationAfterCalculation();
    }

  };

  private validateEnteredParams() {

    // Reset flags
    this.resetAllFlags();

    const warmLiquid: string = this.parametersEntered.warm ? this.parametersEntered.warm.liquid.name : null;
    const coldLiquid: string = this.parametersEntered.cold ? this.parametersEntered.cold.liquid.name : null;


    // Check that liquid entered.
    if (!warmLiquid && !coldLiquid) {
      this.validationError = true;
      this.liquidNotSelectedErrorMsg = 'Fluid is not entered on both sides';
      this.enteredParamsValidated.emit({ valid: false });
      return;
    } else if (!warmLiquid) {
      this.validationError = true;
      this.liquidNotSelectedErrorMsg = 'Fluid is not entered on warm side';
      this.enteredParamsValidated.emit({ valid: false });
      return;
    } else if (!coldLiquid) {
      this.validationError = true;
      this.liquidNotSelectedErrorMsg = 'Fluid is not entered on cold side';
      this.enteredParamsValidated.emit({ valid: false });
      return;
    };

    // Validate some params for Condensation
    if (this.warmAppType === 'CO') {

      if (!this.parametersEntered.warm.satTemp.value.commonUnits && !this.parametersEntered.warm.satTemp.entered) {
        this.validationError = true;
        this.saturationParamsNotEntered = true;
        this.enteredParamsValidated.emit({ valid: false });
        return;
      };

      if (!this.parametersEntered.warm.satPressure.value.commonUnits && !this.parametersEntered.warm.satPressure.entered) {
        this.validationError = true;
        this.saturationParamsNotEntered = true;
        this.enteredParamsValidated.emit({ valid: false });
        return;
      };

      if (!this.parametersEntered.warm.outletTemp.value.commonUnits && !this.parametersEntered.warm.outletTemp.entered) {
        this.validationError = true;
        this.outletTempWarmNotEntered = true;
        this.enteredParamsValidated.emit({ valid: false });
        return;
      };

    };

    //Validate some parameters for Evaporation
    if (this.coldAppType === 'EV') {
      if (!this.parametersEntered.cold.satTemp.value.commonUnits && !this.parametersEntered.cold.satTemp.entered) {
        this.validationError = true;
        this.saturationParamsNotEntered = true;
        this.enteredParamsValidated.emit({ valid: false });
        return;
      };
      if (!this.parametersEntered.cold.satPressure.value.commonUnits && !this.parametersEntered.cold.satPressure.entered) {
        this.validationError = true;
        this.saturationParamsNotEntered = true;
        this.enteredParamsValidated.emit({ valid: false });
        return;
      };
    }
    // WARM Side
    let warmEnteredParams: number = null;
    let warmEmptyParams: number = null;
    let warmCalculatedParams: number = null;

    if (this.warmAppType === 'LI' || this.warmAppType === 'GA') {
      warmEnteredParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.inletTemp, this.parametersEntered.warm.outletTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
      warmEmptyParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.inletTemp, this.parametersEntered.warm.outletTemp].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
      warmCalculatedParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.inletTemp, this.parametersEntered.warm.outletTemp].filter((x) => x.value.commonUnits && x.calculated).length;
    } else if (this.warmAppType === 'CO') {
      warmEnteredParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.satTemp, this.parametersEntered.warm.satPressure].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
      warmEmptyParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.satTemp, this.parametersEntered.warm.satPressure].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
      warmCalculatedParams = [this.parametersEntered.warm.massFlowRate, this.parametersEntered.warm.satTemp, this.parametersEntered.warm.satPressure].filter((x) => x.value.commonUnits && x.calculated).length;
    };

    // COLD SIde
    let coldEnteredParams: number = null;
    let coldEmptyParams: number = null;
    let coldCalculatedParams: number = null;

    if (this.coldAppType === 'LI' || this.coldAppType === 'GA') {
      coldEnteredParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.outletTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
      coldEmptyParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.outletTemp].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
      coldCalculatedParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.outletTemp].filter((x) => x.value.commonUnits && x.calculated).length;
    } else if (this.coldAppType === 'EV') {
      coldEnteredParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.satPressure, this.parametersEntered.cold.satTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.entered).length;
      coldEmptyParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.satPressure, this.parametersEntered.cold.satTemp].filter((x) => !x.value.commonUnits && !x.entered && !x.calculated).length;
      coldCalculatedParams = [this.parametersEntered.cold.massFlowRate, this.parametersEntered.cold.inletTemp, this.parametersEntered.cold.satPressure, this.parametersEntered.cold.satTemp].filter((x) => (x.value.commonUnits || x.value.commonUnits === 0) && x.calculated).length;
    };


    // Heat Exchange Rate
    const heRateEmpty = !this.heatExchangeRate.entered && !this.heatExchangeRate.calculated ? 1 : 0;
    const heRateEntered = this.heatExchangeRate.entered ? 1 : 0;
    const heRateCalculated = this.heatExchangeRate.calculated ? 1 : 0;
    const allEmptyParams = warmEmptyParams + coldEmptyParams + heRateEmpty;


    const allEnteredParams = warmEnteredParams + coldEnteredParams + heRateEntered;
    const tooManyParams = allEmptyParams + warmCalculatedParams + coldCalculatedParams + heRateCalculated;

    // 1. Validation for Liquid/Liquid Application
    // 2. Validation for Condensation/Liquid Application
    // 3. Validation for Liquid/Evaporation Application
    if ((this.warmAppType === 'LI' && this.coldAppType === 'LI')
      ||(this.warmAppType === 'GA' && this.coldAppType === 'GA')
      ||(this.warmAppType === 'LI' && this.coldAppType === 'GA')
      ||(this.warmAppType === 'GA' && this.coldAppType === 'LI')){
      if (allEmptyParams > 2 || allEnteredParams < 5) {
        this.validationError = true;
        this.underdeterminedErrorMoreParams = true;

      } else if ((warmEmptyParams + warmCalculatedParams) === 2 || (coldEmptyParams + coldCalculatedParams) === 2) {
        this.validationError = true;
        this.underdeterminedErrorDiffSides = true;

      } else if (tooManyParams < 2) {
        this.validationError = true;
        this.overdeterminedError = true;
      };

    } else if ((this.warmAppType === 'CO' && this.coldAppType === 'LI')
            ||(this.warmAppType === 'CO' && this.coldAppType === 'GA')) {
      if (allEmptyParams > 2 || allEnteredParams < 5) {
        this.validationError = true;
        this.underdeterminedErrorMoreParams = true;

      } else if ((warmEmptyParams + warmCalculatedParams) === 2 || (coldEmptyParams + coldCalculatedParams) === 2) {
        this.validationError = true;
        this.underdeterminedErrorDiffSides = true;

      } else if (tooManyParams < 1 || tooManyParams === 1) {
        this.validationError = true;
        this.overdeterminedError = true;
      };
    } else if ((this.warmAppType === 'LI' && this.coldAppType === 'EV')
            ||(this.warmAppType === 'GA' && this.coldAppType === 'EV')) {
      if (allEmptyParams > 2 || allEnteredParams < 6) {
        this.validationError = true;
        this.underdeterminedErrorMoreParams = true;

      } else if ((warmEmptyParams + warmCalculatedParams) === 2 || (coldEmptyParams + coldCalculatedParams) === 2) {
        this.validationError = true;
        this.underdeterminedErrorDiffSides = true;

      } else if (tooManyParams < 1 || tooManyParams === 1) {
        this.validationError = true;
        this.overdeterminedError = true;
      };
    } else if (this.warmAppType === 'CO' && this.coldAppType === 'EV') {
      if (allEmptyParams > 2 || allEnteredParams < 6) {
        this.validationError = true;
        this.underdeterminedErrorMoreParams = true;

      } else if ((warmEmptyParams + warmCalculatedParams) === 2 || (coldEmptyParams + coldCalculatedParams) === 2) {
        this.validationError = true;
        this.underdeterminedErrorDiffSides = true;

      } else if (tooManyParams < 1 || tooManyParams === 1) {
        this.validationError = true;
        this.overdeterminedError = true;
      };
    };

    if (this.validationError) {
      this.enteredParamsValidated.emit({ valid: false });
    } else {
      this.enteredParamsValidated.emit({ valid: true });
    };
  };

  private validationAfterCalculation() {
    // Reset flags
    this.resetAllFlags();

    if (this.parametersCalculated.warm.massFlowRate.value.commonUnits < 0) {
      this.validationError = true;
      this.warmMassFlowRateError = true;
    };

    if (this.parametersCalculated.cold.massFlowRate.value.commonUnits < 0) {
      this.validationError = true;
      this.coldMassFlowRateError = true;
    };

    const warmParams = this.parametersCalculated.warm;
    const coldParams = this.parametersCalculated.cold;

    // Validation rules for some applications
    if (this.warmAppType === 'LI' || this.warmAppType === 'GA') {
      const fluidTemperatures = warmParams.fluidProps['specific_heat_cap'].map((_x) => _x.dependency1Value);
      const fluidMinTemp = Math.min.apply(Math, fluidTemperatures);
      const fluidMaxTemp = Math.max.apply(Math, fluidTemperatures);

      const inletTemp = warmParams.inletTemp.value.commonUnits;
      const outletTemp = warmParams.outletTemp.value.commonUnits;

      if ((inletTemp < fluidMinTemp || inletTemp > fluidMaxTemp) || (outletTemp < fluidMinTemp || outletTemp > fluidMaxTemp)) {
        // this.validationWarning = true;
        this.warmFluidTempNotInRangeWarning = true;
      };
    } else if (this.warmAppType === 'CO') {
      const fluidTemperatures = warmParams.fluidProps['sat_pressure'].map((_x) => _x.dependency1Value);
      const fluidMinTemp = Math.min.apply(Math, fluidTemperatures);
      const fluidMaxTemp = Math.max.apply(Math, fluidTemperatures);

      const satTemp = warmParams.satTemp.value.commonUnits;
      const outletTemp = warmParams.outletTemp.value.commonUnits;

      if ((satTemp < fluidMinTemp || satTemp > fluidMaxTemp) || (outletTemp < fluidMinTemp || outletTemp > fluidMaxTemp)) {
        // this.validationWarning = true;
        this.warmFluidTempNotInRangeWarning = true;
      };
    };


    if (this.coldAppType === 'LI' || this.coldAppType === 'GA') {
      const fluidTemperatures = coldParams.fluidProps['specific_heat_cap'].map((_x) => _x.dependency1Value);
      const fluidMinTemp = Math.min.apply(Math, fluidTemperatures);
      const fluidMaxTemp = Math.max.apply(Math, fluidTemperatures);

      const inletTemp = coldParams.inletTemp.value.commonUnits;
      const outletTemp = coldParams.outletTemp.value.commonUnits;

      if ((inletTemp < fluidMinTemp || inletTemp > fluidMaxTemp) || (outletTemp < fluidMinTemp || outletTemp > fluidMaxTemp)) {
        // this.validationWarning = true;
        this.coldFluidTempNotInRangeWarning = true;
      };
    };

    // Validation rules for different application combinations
    // 1. Application Liquid/Liquid
    // 2. Application Condensation/Liquid
    // 3. Application Liquid/Evaporation
    if ((this.warmAppType === 'LI' && this.coldAppType === 'LI')
      ||(this.warmAppType === 'GA' && this.coldAppType === 'GA')
      ||(this.warmAppType === 'LI' && this.coldAppType === 'GA')
      ||(this.warmAppType === 'GA' && this.coldAppType === 'LI')) {
      // Warm side
      const warmInletTemp = warmParams.inletTemp.value.commonUnits;
      const warmOutletTemp = warmParams.outletTemp.value.commonUnits;
      const warmMaxPressureDrop = warmParams.maxPressureDrop.value.commonUnits;

      // Cold side
      const coldInletTemp = coldParams.inletTemp.value.commonUnits;
      const coldOutletTemp = coldParams.outletTemp.value.commonUnits;
      const coldMaxPressureDrop = coldParams.maxPressureDrop.value.commonUnits;

      if ((coldInletTemp >= warmOutletTemp) || (coldOutletTemp >= warmInletTemp) || (coldInletTemp > warmInletTemp) || (coldInletTemp >= coldOutletTemp) || (warmOutletTemp >= warmInletTemp)) {
        this.validationError = true;
        this.notAcceptableTemperature = true;
      };

      // Max Pressure Drop
      if (warmMaxPressureDrop <= 0) {
        this.validationError = true;
        this.warmMaxPressureDropError = true;
      };

      if (coldMaxPressureDrop <= 0) {
        this.validationError = true;
        this.coldMaxPressureDropError = true;
      };
    } else if ((this.warmAppType === 'CO' && this.coldAppType === 'LI')
            ||(this.warmAppType === 'CO' && this.coldAppType === 'GA')){

      const warmSatTemp: number = this.parametersCalculated.warm.satTemp.value.commonUnits;
      const warmOutletTemp: number = this.parametersCalculated.warm.outletTemp.value.commonUnits;

      const coldInletTemp: number = this.parametersCalculated.cold.inletTemp.value.commonUnits;
      const coldOutletTemp: number = this.parametersCalculated.cold.outletTemp.value.commonUnits;
      const coldMaxPressureDrop = coldParams.maxPressureDrop.value.commonUnits;

      if (coldInletTemp > warmOutletTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Outlet temperature on the warm side');
      } else if (coldOutletTemp > warmSatTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Outlet temperature on the cold side cannot be higher than Saturation temperature');
      } else if (coldInletTemp > coldOutletTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Outlet temperature on the cold side');
      } else if (warmOutletTemp > warmSatTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be higher than Saturation temperature');
      };

      if (coldMaxPressureDrop <= 0) {
        this.validationError = true;
        this.coldMaxPressureDropError = true;
      };

    } else if ((this.warmAppType === 'LI' && this.coldAppType === 'EV')
            ||(this.warmAppType === 'GA' && this.coldAppType === 'EV')) {
      const warmOutletTemp: number = this.parametersCalculated.warm.outletTemp.value.commonUnits;
      const warmInletTemp: number = this.parametersCalculated.warm.inletTemp.value.commonUnits;
      const warmMaxPressureDrop = warmParams.maxPressureDrop.value.commonUnits;

      const coldInletTemp: number = this.parametersCalculated.cold.inletTemp.value.commonUnits;
      const coldSatTemp: number = this.parametersCalculated.cold.satTemp.value.commonUnits;

      if (warmOutletTemp < coldInletTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be lower than Inlet temperature on the cold side');
      } else if (warmInletTemp < coldSatTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Inlet temperature on the warm side cannot be lower than Saturation temperature');
      } else if (warmInletTemp < warmOutletTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be higher than Inlet temperature on the warm side');
      } else if (coldInletTemp > coldSatTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Saturation temperature');
      };

      if (warmMaxPressureDrop <= 0) {
        this.validationError = true;
        this.warmMaxPressureDropError = true;
      };
    } else if (this.warmAppType === 'CO' && this.coldAppType === 'EV') {
      const warmOutletTemp: number = this.parametersCalculated.warm.outletTemp.value.commonUnits;
      const warmSatTemp: number = this.parametersCalculated.warm.satTemp.value.commonUnits;

      const coldInletTemp: number = this.parametersCalculated.cold.inletTemp.value.commonUnits;
      const coldSatTemp: number = this.parametersCalculated.cold.satTemp.value.commonUnits;

      if (coldInletTemp > warmOutletTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Outlet temperature on the warm side');
      } else if (coldSatTemp > warmSatTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Saturation temperature on the cold side cannot be higher than Saturation temperature on the warm side');
      } else if (coldInletTemp > coldSatTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Saturation temperature on the cold side');
      } else if (warmOutletTemp > warmSatTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be higher than Saturation temperature on the warm side');
      } else if (warmOutletTemp < coldInletTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be lower than Inlet temperature on the cold side');
      } else if (warmSatTemp < coldSatTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Inlet temperature on the warm side cannot be lower than Saturation temperature on the warm side');
      } else if (warmSatTemp < warmOutletTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Outlet temperature on the warm side cannot be higher than Saturation temperature on the warm side');
      } else if (coldInletTemp > coldSatTemp) {
        this.validationError = true;
        this.validationsErrorsListOfTemp.push('Inlet temperature on the cold side cannot be higher than Saturation temperature');
      };
    };

    // Minimum overdesign
    if (!this.overdesign && this.overdesign !== 0) {
      this.validationError = true;
      this.notValidOverdesign = true;
    };

    if (this.validationError) {
      this.calculatedParamsValidated.emit({ valid: false });
    } else {
      this.calculatedParamsValidated.emit({ valid: true });
    };

  };

  private resetAllFlags(): void {

    // Reset flags
    this.validationError = false;
    this.liquidNotSelectedErrorMsg = null;
    this.underdeterminedErrorMoreParams = false;
    this.underdeterminedErrorDiffSides = false;
    this.overdeterminedError = false;
    this.saturationParamsNotEntered = false;
    this.outletTempWarmNotEntered = false;
    this.warmMassFlowRateError = false;
    this.warmFluidTempNotInRangeWarning = false;
    this.warmMaxPressureDropError = false;
    this.coldMassFlowRateError = false;
    this.coldFluidTempNotInRangeWarning = false;
    this.coldMaxPressureDropError = false;
    this.notAcceptableTemperature = false;
    this.notValidOverdesign = false;
    this.validationsErrorsListOfTemp = [];
  }

}
