import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import * as shared from './index';
import { FormsModule } from '@angular/forms';

// Material
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

// import ngx-translate and the http loader
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    shared.HomePageComponent,
    shared.HeaderComponent,
    shared.LanguageMenuComponent,
    shared.ToolBarComponent,
    shared.ProgressBarComponent,
    shared.UsermenuComponent,
    shared.FooterComponent,
    shared.ConfiguratorComponent,
    shared.DutyComponent,
    shared.DutyValidationComponent,
    shared.LiquidComponent,
    shared.UserSettingsComponent,
    shared.OptionsComponent,
    shared.AccountsManagementComponent,
    shared.SolutionComponent,
    shared.CondensationComponent,
    shared.EvaporationComponent,
    shared.GasComponent,
    shared.TubesheetsInfoComponent,
    // Assistant pages
    shared.AssistantMainPageComponent,
    shared.AssistantCountryComponent,
    shared.AssistantDesignParametersComponent,
    shared.AssistantHETypeComponent,
    shared.AssistantApplicationComponent,
    shared.AssistantCleanabilityComponent,
    shared.AssistantSizeRestrictionsComponent,
    shared.DutySideMenuSettingsComponent,
    shared.ExtraDutyComponent,
    shared.ZiloHintsComponent,
    shared.AssistantCondensationDetailsComponent,
    shared.AssistantEvaporationDetailsComponent,
    shared.HeatExchangerViewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MatTreeModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatCheckboxModule,
    MatSortModule,
    MatTabsModule,
    MatCardModule,
    MatProgressSpinnerModule,
    // ngx-translate and the loader module
    TranslateModule.forChild()
  ],
  exports: [
    shared.HomePageComponent,
    shared.HeaderComponent,
    shared.LanguageMenuComponent,
    shared.ToolBarComponent,
    shared.ProgressBarComponent,
    shared.UsermenuComponent,
    shared.FooterComponent,
    shared.ConfiguratorComponent,
    shared.AssistantApplicationComponent,
    shared.DutyComponent,
    shared.UserSettingsComponent,
    shared.OptionsComponent,
    shared.AccountsManagementComponent,
  ]
})
export class SharedModule { }
