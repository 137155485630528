<!-- duty validation comp start -->
<div class="duty-validation-comp">
  <!-- Validation messages before calculation start-->
  <div class="error" *ngIf="liquidNotSelectedErrorMsg"><span>{{liquidNotSelectedErrorMsg}}</span></div>
  <div class="error" *ngIf="underdeterminedErrorMoreParams">
    <span>
      Cannot solve undetermined system - more parameters should be specified
    </span>
  </div>
  <div class="error" *ngIf="underdeterminedErrorDiffSides"><span>Cannot solve undetermined system - calculated
      parameters should be on different sides</span>
  </div>
  <div class="error" *ngIf="overdeterminedError"><span>Overdetermined system - too many parameters have been
      specified</span>
  </div>

  <!-- Condensation start -->
  <div class="error" *ngIf="saturationParamsNotEntered"><span>Saturation temperature/pressure is not entered</span>
  </div>
  <div class="error" *ngIf="outletTempWarmNotEntered"><span>Outlet temperature is not entered the warm side</span>
  </div>
  <!-- Condensation end -->

  <!-- Validation messages before calculation end-->

  <!-- Validation messages after calculation -->
  <div class="error" *ngIf="warmMassFlowRateError"><span>Mass flow rate must be above 0 on Warm side</span>
  </div>
  <div class="error" *ngIf="coldMassFlowRateError"><span>Mass flow rate must be above 0 on Cold side</span>
  </div>

  <div class="error" *ngIf="warmMaxPressureDropError"><span>Not valid max pressure drop on Warm side</span>
  </div>
  <div class="error" *ngIf="coldMaxPressureDropError"><span>Not valid max pressure drop on Cold side</span>
  </div>

  <div class="error" *ngIf="warmFluidTempNotInRangeWarning">
    <span>
      {{parametersCalculated.warm.liquid.name}} fluid is beyond the known temperature range. Calculations may not be
      correct
    </span>
  </div>
  <div class="error" *ngIf="coldFluidTempNotInRangeWarning">
    <span>
      {{parametersCalculated.cold.liquid.name}} fluid is beyond the known temperature range. Calculations may not be
      correct
    </span>
  </div>
  <div class="error" *ngIf="notAcceptableTemperature">
    <span>
      Temperatures Not Acceptable: Hot ({{parametersCalculated.warm.liquid.name}})
      {{parametersCalculated.warm.inletTemp.value.userUnits}} ->
      {{parametersCalculated.warm.outletTemp.value.userUnits}},
      Cold ({{parametersCalculated.cold.liquid.name}}) {{parametersCalculated.cold.inletTemp.value.userUnits}} ->
      {{parametersCalculated.cold.outletTemp.value.userUnits}}
    </span>
  </div>
  <div class="error" *ngIf="notValidOverdesign">
    <span>Not valid minimum overdesign</span>
  </div>

  <!-- Condensation\Evaporation list of temperature errors start -->
  <div class="error" *ngIf="validationsErrorsListOfTemp.length>0">
    <span class="block" *ngFor="let error of validationsErrorsListOfTemp">{{error}}</span>
  </div>
  <!-- Condensation\Evaporation list of temperature errors end -->

</div>
<!-- duty validation comp end -->