<!-- header start -->
<app-header>
  <app-usermenu></app-usermenu>
</app-header>
<!-- header end -->

<!-- solution-comp start -->
<div class="solution-comp">
  <!-- main-container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">Back</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="9"></app-progressbar>
    <!-- progressbar end -->


    <!-- main title start -->
    <div class="page-title">
      Your Selection
    </div>
    <!-- main title end -->

    <!-- solution title start -->
    <div class="solution-title" *ngIf="solutionName">
      <span class="one">TYPE</span> <span class="two">{{solutionName[0]}}</span>
    </div>
    <!-- solution title end -->

    <!-- solution-type start -->
    <div class="solution-type" *ngIf="solutionTemaType">– similar to TEMA type
      {{solution.solutionData.mechanical_data.TemaType}} –</div>
    <!-- solution-type end -->


    <!-- solution start -->
    <div class="solution" *ngIf="solution">
      <!-- solution-main-detailes start -->
      <div class="solution-main-detailes">
        <!-- materials start -->
        <div class="block materials">
          <!-- row start -->
          <div class="row text-center"> Main Materials</div>
          <!-- row end -->

          <!-- parameter start -->
          <div class="row parameter">
            <!-- name start -->
            <div class="name">Shell</div>
            <!-- name end -->
            <!-- value start -->
            <div class="value"><b>{{materialParameters.shellMaterial.name}}</b>
              {{materialParameters.shellMaterial.description}}</div>
            <!-- value end -->
          </div>
          <!-- parameter end -->
          <!-- parameter start -->
          <div class="row parameter">
            <!-- name start -->
            <div class="name">Tube Sheet(s)</div>
            <!-- name end -->
            <!-- value start -->
            <div class="value"><b>{{materialParameters.tubeSheetMaterial.name}}</b>
              {{materialParameters.tubeSheetMaterial.description}}</div>
            <!-- value end -->
          </div>
          <!-- parameter end -->
          <!-- parameter start -->
          <div class="row parameter">
            <!-- name start -->
            <div class="name">Tubes</div>
            <!-- name end -->
            <!-- value start -->
            <div class="value"><b>{{materialParameters.tubeMaterial.name}}</b>
              {{materialParameters.tubeMaterial.description}}</div>
            <!-- value end -->
          </div>
          <!-- parameter end -->
          <!-- parameter start -->
          <div class="row parameter">
            <!-- name start -->
            <div class="name">Head</div>
            <!-- name end -->
            <!-- value start -->
            <div class="value"><b>{{materialParameters.headMaterial.name}}</b>
              {{materialParameters.headMaterial.description}}</div>
            <!-- value end -->
          </div>
          <!-- parameter end -->
          <!-- parameter start -->
          <div class="row parameter">
            <!-- name start -->
            <div class="name">Supports</div>
            <!-- name end -->
            <!-- value start -->
            <div class="value"><b>{{materialParameters.supportsMaterial.name}}</b>
              {{materialParameters.supportsMaterial.description}}</div>
            <!-- value end -->
          </div>
          <!-- parameter end -->
          <!-- parameter start -->
          <div class="row-gasket">
            <!-- name start -->
            <div class="name">Gaskets</div>
            <!-- name end -->
            <div class="gaskets-select-container">
              <!-- select start -->
              <select class="select" name="gaskets" id="gaskets" *ngIf="gaskets?.visible"
                [ngModel]="gaskets.selectedValue" (ngModelChange)="onSelectChange(gaskets, $event)"
                [disabled]="!gaskets.enabled">
                <option *ngFor="let option of gaskets.options" [ngValue]="option.id">{{option.name}}
                </option>
              </select>
              <!-- select end -->
            </div>
          </div>
          <!-- parameter end -->


          <!-- parameter start -->
          <div class="row parameter">
            <!-- name start -->
            <div class="name">Design Code</div>
            <!-- name end -->
            <!-- value start -->
            <div class="value"><b>{{materialParameters.regulatory}}</b></div>
            <!-- value end -->
          </div>
          <!-- parameter end --><!-- parameter start -->
          <div class="row parameter" *ngIf="materialParameters.ped!='None'">
            <!-- name start -->
            <div class="name">PED 2014/68/EU</div>
            <!-- name end -->
            <!-- value start -->
            <div class="value"><b>{{materialParameters.ped}}</b></div>
            <!-- value end -->
          </div>
          <!-- parameter end --><!-- parameter start -->
          <div class="row parameter" *ngIf="materialParameters.module">
            <!-- name start -->
            <div class="name">Module</div>
            <!-- name end -->
            <!-- value start -->
            <div class="value"><b>{{materialParameters.module}}</b></div>
            <!-- value end -->
          </div>
          <!-- parameter end -->
        </div>
        <!-- materials end -->

        <!-- Horizontal orientation images start -->
        <div class="block images horizontal" *ngIf="solution.solutionData.mechanical_data.OrientationName === 'HO'">

          <!-- main-image-block start -->
          <div class="main-image-block">
            <!-- FV preview start -->
            <div class="fv" #dxfContainerFV></div>
            <!-- FV preview end -->
          </div>
          <!-- main-image-block end -->

          <!-- additional-images-block start -->
          <div class="additional-images-block">
            <!-- SV preview start -->
            <div class="left" #dxfContainerSV></div>
            <!-- SV preview end -->

            <!-- hot side image start -->
            <img class="right" *ngIf="solution.solutionData.mechanical_data.HotSideOnShell"
              src="../../../assets/images/hotSideOnShell_true.png" alt="">
            <img class="right" *ngIf="!solution.solutionData.mechanical_data.HotSideOnShell"
              src="../../../assets/images/hotSideOnShell_false.png" alt="">
            <!-- hot side image end -->
          </div>
          <!-- additional-images-block end -->

        </div>
        <!-- Horizontal orientation images end -->

        <!-- Vetical orientation images start -->
        <div class="block images vertical" *ngIf="solution.solutionData.mechanical_data.OrientationName === 'VE'">

          <!-- main-image-block start -->
          <div class="main-image-block">
            <!-- FV preview start -->
            <div class="fv" #dxfContainerFV></div>
            <!-- FV preview end -->
          </div>
          <!-- main-image-block end -->

          <!-- additional-images-block start -->
          <div class="additional-images-block">
            <!-- SV preview start -->
            <div class="top" #dxfContainerSV></div>
            <!-- SV preview end -->

            <!-- hot side image start -->
            <img class="bottom" *ngIf="solution.solutionData.mechanical_data.HotSideOnShell"
              src="../../../assets/images/hotSideOnShell_true.png" alt="">
            <img class="bottom" *ngIf="!solution.solutionData.mechanical_data.HotSideOnShell"
              src="../../../assets/images/hotSideOnShell_false.png" alt="">
            <!-- hot side image end -->
          </div>
          <!-- additional-images-block end -->

        </div>
        <!-- Vetical orientation images end -->
      </div>
      <!-- solution-main-detailes end -->

      <!-- sides-parameters start -->
      <div class="sides-parameters">

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column red main-title">WARM SIDE</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title"></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue main-title">COLD SIDE</div>
          <!-- column end -->

        </div>
        <!--row end-->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column red"><b>{{sideParameters.warm.fluidName}}</b></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title">Fluid</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue"><b>{{sideParameters.cold.fluidName}}</b></div>
          <!-- column end -->
        </div>
        <!--row end-->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column red"><b>{{sideParameters.warm.inletTemp}}</b></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title">Inlet ({{physicalPropsUserUnitSystemData.temperature.unitSign}})</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue"><b>{{sideParameters.cold.inletTemp}}</b></div>
          <!-- column end -->
        </div>
        <!--row end-->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column red"><b>{{sideParameters.warm.outletTemp}}</b></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title">Outlet ({{physicalPropsUserUnitSystemData.temperature.unitSign}})</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue"><b>{{sideParameters.cold.outletTemp}}</b></div>
          <!-- column end -->

        </div>
        <!--row end-->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column red"><b>{{sideParameters.warm.massFlowRate}}</b></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title">Mass Flow Rate ({{physicalPropsUserUnitSystemData.mass_flow_rate.unitSign}})</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue"><b>{{sideParameters.cold.massFlowRate}}</b></div>
          <!-- column end -->

        </div>
        <!--row end-->

        <!--row start-->
        <div class="row" *ngIf="sideParameters.warm.workPressure && sideParameters.cold.workPressure">
          <!-- column start -->
          <div class="column red"><b>{{sideParameters.warm.workPressure}}</b></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title">Working Pressure ({{physicalPropsUserUnitSystemData.pressure.unitSign}})</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue"><b>{{sideParameters.cold.workPressure}}</b></div>
          <!-- column end -->

        </div>
        <!--row end-->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column red"><b>{{sideParameters.warm.maxPressureDrop}}</b></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title">Pressure Drop ({{physicalPropsUserUnitSystemData.pressure_drop.unitSign}})</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue"><b>{{sideParameters.cold.maxPressureDrop}}</b></div>
          <!-- column end -->

        </div>
        <!--row end-->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column red"></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title">Overdesign <b>{{sideParameters.overdesign}} %</b></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue"></div>
          <!-- column end -->

        </div>
        <!--row end-->


        <!-- extra-duty-container start -->
        <div class="extra-duty-container" *ngIf="extraDuties?.length >0">

          <!-- extra-duty start -->
          <div class="extra-duty" *ngFor="let duty of extraDuties">
            <!-- extra-duty-title start -->
            <div class="extra-duty-title">Loadcase "{{duty.name}}" <img src="../../../assets/icons/delete_icon.png"
                alt="delete_icon.png" (click)="deleteExtraDuty(duty.requirementId)"></div>
            <!-- extra-duty-title end -->

            <!-- extra-duty-row start -->
            <div class="extra-duty-row">
              <!-- column start -->
              <div class="column">Heat Exchange Rate ({{physicalPropsUserUnitSystemData.heat_exchange_rate.unitSign}})
                <span class="value">{{duty.heatExchangeRate}}</span>
              </div>
              <!-- column end -->
              <!-- column start -->
              <div class="column title"></div>
              <!-- column end -->
              <!-- column start -->
              <div class="column">Overdesign (%)<span class="value">{{duty.overdesign}}</span></div>
              <!-- column end -->
            </div>
            <!-- extra-duty-row end -->


            <!-- side-parameters -->
            <div class="sides-parameters">
              <!--row start-->
              <div class="row">
                <!-- column start -->
                <div class="column red main-title">WARM SIDE</div>
                <!-- column end -->
                <!-- column start -->
                <div class="column title"></div>
                <!-- column end -->
                <!-- column start -->
                <div class="column blue main-title">COLD SIDE</div>
                <!-- column end -->
              </div>
              <!--row end-->

              <!--row start-->
              <div class="row">
                <!-- column start -->
                <div class="column red"><b>{{duty.warm.fluidName}}</b></div>
                <!-- column end -->
                <!-- column start -->
                <div class="column title">Fluid</div>
                <!-- column end -->
                <!-- column start -->
                <div class="column blue"><b>{{duty.cold.fluidName}}</b></div>
                <!-- column end -->
              </div>
              <!--row end-->

              <!--row start-->
              <div class="row">
                <!-- column start -->
                <div class="column red"><b>{{duty.warm.inletTemp}}</b></div>
                <!-- column end -->
                <!-- column start -->
                <div class="column title">Inlet ({{physicalPropsUserUnitSystemData.temperature.unitSign}})</div>
                <!-- column end -->
                <!-- column start -->
                <div class="column blue"><b>{{duty.cold.inletTemp}}</b></div>
                <!-- column end -->
              </div>
              <!--row end-->

              <!--row start-->
              <div class="row">
                <!-- column start -->
                <div class="column red"><b>{{duty.warm.outletTemp}}</b></div>
                <!-- column end -->
                <!-- column start -->
                <div class="column title">Outlet ({{physicalPropsUserUnitSystemData.temperature.unitSign}})</div>
                <!-- column end -->
                <!-- column start -->
                <div class="column blue"><b>{{duty.cold.outletTemp}}</b></div>
                <!-- column end -->

              </div>
              <!--row end-->

              <!--row start-->
              <div class="row">
                <!-- column start -->
                <div class="column red"><b>{{duty.warm.massFlowRate}}</b></div>
                <!-- column end -->
                <!-- column start -->
                <div class="column title">Mass Flow Rate ({{physicalPropsUserUnitSystemData.mass_flow_rate.unitSign}})
                </div>
                <!-- column end -->
                <!-- column start -->
                <div class="column blue"><b>{{duty.cold.massFlowRate}}</b></div>
                <!-- column end -->

              </div>
              <!--row end-->

              <!--row start-->
              <div class="row" *ngIf="duty.warm.workPressure || duty.cold.workPressure">
                <!-- column start -->
                <div class="column red"><b>{{duty.warm.workPressure}}</b></div>
                <!-- column end -->
                <!-- column start -->
                <div class="column title">Working Pressure ({{physicalPropsUserUnitSystemData.pressure.unitSign}})</div>
                <!-- column end -->
                <!-- column start -->
                <div class="column blue"><b>{{duty.cold.workPressure}}</b></div>
                <!-- column end -->

              </div>
              <!--row end-->

              <!--row start-->
              <div class="row">
                <!-- column start -->
                <div class="column red"><b>{{duty.warm.maxPressureDrop}}</b></div>
                <!-- column end -->
                <!-- column start -->
                <div class="column title">Pressure Drop ({{physicalPropsUserUnitSystemData.pressure_drop.unitSign}})
                </div>
                <!-- column end -->
                <!-- column start -->
                <div class="column blue"><b>{{duty.cold.maxPressureDrop}}</b></div>
                <!-- column end -->

              </div>
              <!--row end-->

              <!-- orw start -->
              <div class="row warnings-row" *ngIf="!!duty.warnings">
                <div class="row warning" *ngFor="let warning of duty.warnings">
                  {{warning.Message}}
                </div>
              </div>
              <!-- orw end -->

            </div>
            <!-- side-parameters -->

          </div>
          <!-- extra-duty end -->

        </div>
        <!-- extra-duty-container end -->

        <!-- row start load case button -->
        <div class="row">

          <!-- add-case-btn start -->
          <a href="{{extraDutyUrl}}" class="add-case-btn">Add another load case</a>
          <!-- add-case-btn end -->

        </div>
        <!-- row end load case button -->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column red"><b>{{sideParameters.warm.maxPressure}}
              {{physicalPropsUserUnitSystemData.pressure.unitSign}}</b></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title">Design Pressure</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue"><b>{{sideParameters.cold.maxPressure}}
              {{physicalPropsUserUnitSystemData.pressure.unitSign}}</b></div>
          <!-- column end -->
        </div>
        <!--row end-->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column red"><b>{{sideParameters.warm.maxTemperature}}
              {{physicalPropsUserUnitSystemData.temperature.unitSign}}</b></div>
          <!-- column end -->
          <!-- column start -->
          <div class="column title">Design Temperature</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column blue"><b>{{sideParameters.cold.maxTemperature}}
              {{physicalPropsUserUnitSystemData.temperature.unitSign}}</b></div>
          <!-- column end -->
        </div>
        <!--row end-->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column-for-select red">
            <!-- select start -->
            <select class="select small" name="warmInletPort" id="warmInletPort" *ngIf="warmInletPort"
              [ngModel]="warmInletPort.selectedValue" (ngModelChange)="onSelectChange(warmInletPort, $event)"
              [disabled]="!warmInletPort.enabled">
              <option *ngFor="let option of warmInletPort.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
            <!-- select start -->
            <select class="select small" name="warmInletFlangePN" id="warmInletFlangePN" *ngIf="warmInletFlangePN"
              [ngModel]="warmInletFlangePN.selectedValue" (ngModelChange)="onSelectChange(warmInletFlangePN, $event)">
              <option *ngFor="let option of warmInletFlangePN.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
            <!-- select start -->
            <select class="select" name="warmInletFlange" id="warmInletFlange" *ngIf="warmInletFlange"
              [ngModel]="warmInletFlange.selectedValue" (ngModelChange)="onSelectChange(warmInletFlange, $event)">
              <option *ngFor="let option of warmInletFlange.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
          </div>
          <!-- column end -->
          <!-- column start -->
          <div class="column-for-select title">Inlet Port</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column-for-select blue">
            <!-- select start -->
            <select class="select small" name="coldInletPort" id="coldInletPort" *ngIf="coldInletPort"
              [ngModel]="coldInletPort.selectedValue" (ngModelChange)="onSelectChange(coldInletPort, $event)">
              <option *ngFor="let option of coldInletPort.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
            <!-- select start -->
            <select class="select small" name="coldInletFlangePN" id="coldInletFlangePN" *ngIf="coldInletFlangePN"
              [ngModel]="coldInletFlangePN.selectedValue" (ngModelChange)="onSelectChange(coldInletFlangePN, $event)">
              <option *ngFor="let option of coldInletFlangePN.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
            <!-- select start -->
            <select class="select" name="coldInletFlange" id="coldInletFlange" *ngIf="coldInletFlange"
              [ngModel]="coldInletFlange.selectedValue" (ngModelChange)="onSelectChange(coldInletFlange, $event)">
              <option *ngFor="let option of coldInletFlange.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
          </div>
          <!-- column end -->
        </div>
        <!--row end-->

        <!--row start-->
        <div class="row">
          <!-- column start -->
          <div class="column-for-select red">
            <!-- select start -->
            <select class="select small" name="warmOutletPort" id="warmOutletPort" *ngIf="warmOutletPort"
              [ngModel]="warmOutletPort.selectedValue" (ngModelChange)="onSelectChange(warmOutletPort, $event)">
              <option *ngFor="let option of warmOutletPort.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
            <!-- select start -->
            <select class="select small" name="warmOutletFlangePN" id="warmOutletFlangePN" *ngIf="warmOutletFlangePN"
              [ngModel]="warmOutletFlangePN.selectedValue" (ngModelChange)="onSelectChange(warmOutletFlangePN, $event)">
              <option *ngFor="let option of warmOutletFlangePN.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
            <!-- select start -->
            <select class="select" name="warmOutletFlange" id="warmOutletFlange" *ngIf="warmOutletFlange"
              [ngModel]="warmOutletFlange.selectedValue" (ngModelChange)="onSelectChange(warmOutletFlange, $event)">
              <option *ngFor="let option of warmOutletFlange.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
          </div>
          <!-- column end -->
          <!-- column start -->
          <div class="column-for-select title">Outlet Port</div>
          <!-- column end -->
          <!-- column start -->
          <div class="column-for-select blue">
            <!-- select start -->
            <select class="select small" name="coldOutletPort" id="coldOutletPort" *ngIf="coldOutletPort"
              [ngModel]="coldOutletPort.selectedValue" (ngModelChange)="onSelectChange(coldOutletPort, $event)">
              <option *ngFor="let option of coldOutletPort.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
            <!-- select start -->
            <select class="select small" name="coldOutletFlangePN" id="coldOutletFlangePN" *ngIf="coldOutletFlangePN"
              [ngModel]="coldOutletFlangePN.selectedValue" (ngModelChange)="onSelectChange(coldOutletFlangePN, $event)">
              <option *ngFor="let option of coldOutletFlangePN.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
            <!-- select start -->
            <select class="select" name="coldOutletFlange" id="coldOutletFlange" *ngIf="coldOutletFlange"
              [ngModel]="coldOutletFlange.selectedValue" (ngModelChange)="onSelectChange(coldOutletFlange, $event)">
              <option *ngFor="let option of coldOutletFlange.options" [ngValue]="option.id">{{option.name}}</option>
            </select>
            <!-- select end -->
          </div>
          <!-- column end -->
        </div>
        <!--row end-->

      </div>
      <!-- sides-parameters end -->

      <!-- options-container start -->
      <div class="options-container">
        <!-- options-container-title start -->
        <div class="options-container-title">Options</div>
        <!-- options-container-title end -->

        <!-- options-rows start -->
        <div class="options-rows">
          <!--row start-->
          <div class="row">
            <!-- column start -->
            <div class="column-for-select red">
              <!-- select start -->
              <select class="select" name="surfaceWarm" id="surfaceWarm" *ngIf="surfaceWarm?.visible"
                [ngModel]="surfaceWarm.selectedValue" (ngModelChange)="onSelectChange(surfaceWarm, $event)"
                [disabled]="!surfaceWarm.enabled">
                <option *ngFor="let option of surfaceWarm.options" [ngValue]="option.id">{{option.name}}</option>
              </select>
              <!-- select end -->
            </div>
            <!-- column end -->

            <!-- column start -->
            <div class="column title">Surface Treatment</div>
            <!-- column end -->

            <!-- column start -->
            <div class="column-for-select blue">
              <!-- select start -->
              <select class="select" name="surfaceCold" id="surfaceCold" *ngIf="surfaceCold?.visible"
                [ngModel]="surfaceCold.selectedValue" (ngModelChange)="onSelectChange(surfaceCold, $event)"
                [disabled]="!surfaceCold.enabled">
                <option *ngFor="let option of surfaceCold.options" [ngValue]="option.id">{{option.name}}</option>
              </select>
              <!-- select end -->
            </div>
            <!-- column end -->
          </div>
          <!--row end-->

          <!-- row start -->
          <div class="row row-align-top hide-border">
            <!-- column start -->
            <div class="column padding-right">

              <!-- checkbox-option-container start -->
              <div class="checkbox-option-container" *ngIf="drainVent?.visible">
                <!-- radio-option-title start -->
                <div class="radio-option-title">Drain and Vent</div>
                <!-- radio-option-title end -->

                <!-- checkbox-container start -->
                <div class="checkbox-container">
                  <mat-checkbox [ngClass]="{'custom-checkbox-color': drainVent.selectedValue}" [color]="'primary'"
                    [checked]="drainVent.selectedValue" (change)="onCheckboxChange(drainVent, $event)"
                    [disabled]="!drainVent.enabled"></mat-checkbox>
                </div>
                <!-- checkbox-container end -->

              </div>
              <!-- checkbox-option-container end -->

              <!-- checkbox-option-container start -->
              <div class="checkbox-option-container" *ngIf="elbow?.visible">
                <!-- radio-option-title start -->
                <div class="radio-option-title">Elbow</div>
                <!-- radio-option-title end -->

                <!-- checkbox-container start -->
                <div class="checkbox-container">
                  <mat-checkbox [ngClass]="{'custom-checkbox-color': elbow.selectedValue}" [color]="'primary'"
                    [checked]="elbow.selectedValue" (change)="onCheckboxChange(elbow, $event)"
                    [disabled]="!elbow.enabled"></mat-checkbox>
                </div>
                <!-- checkbox-container end -->

              </div>
              <!-- checkbox-option-container end -->

              <!-- checkbox-option-container start -->
              <div class="checkbox-option-container" *ngIf="feet?.visible">
                <!-- radio-option-title start -->
                <div class="radio-option-title">Feet</div>
                <!-- radio-option-title end -->

                <!-- checkbox-container start -->
                <div class="checkbox-container">
                  <mat-checkbox [ngClass]="{'custom-checkbox-color': feet.selectedValue}" [color]="'primary'"
                    [checked]="feet.selectedValue" (change)="onCheckboxChange(feet, $event)"
                    [disabled]="!feet.enabled"></mat-checkbox>
                </div>
                <!-- checkbox-container end -->

              </div>
              <!-- checkbox-option-container end -->

            </div>
            <!-- column end -->

            <!-- column start -->
            <div class="column padding-left">

              <!-- checkbox-option-container start -->
              <div class="checkbox-option-container" *ngIf="liftingLugs?.visible">
                <!-- radio-option-title start -->
                <div class="radio-option-title">Lifting Lugs</div>
                <!-- radio-option-title end -->

                <!-- checkbox-container start -->
                <div class="checkbox-container">
                  <mat-checkbox [ngClass]="{'custom-checkbox-color': liftingLugs.selectedValue}" [color]="'primary'"
                    [checked]="liftingLugs.selectedValue" (change)="onCheckboxChange(liftingLugs, $event)"
                    [disabled]="!liftingLugs.enabled"></mat-checkbox>
                </div>
                <!-- checkbox-container end -->

              </div>
              <!-- checkbox-option-container end -->

              <!-- checkbox-option-container start -->
              <div class="checkbox-option-container" *ngIf="notifiedBody?.visible">
                <!-- radio-option-title start -->
                <div class="radio-option-title">Approved by Notified body</div>
                <!-- radio-option-title end -->

                <!-- checkbox-container start -->
                <div class="checkbox-container">
                  <mat-checkbox [ngClass]="{'custom-checkbox-color': notifiedBody.selectedValue}" [color]="'primary'"
                    [checked]="notifiedBody.selectedValue" (change)="onCheckboxChange(notifiedBody, $event)"
                    [disabled]="!notifiedBody.enabled"></mat-checkbox>
                </div>
                <!-- checkbox-container end -->

              </div>
              <!-- checkbox-option-container end -->

              <!-- checkbox-option-container start -->
              <div class="checkbox-option-container" *ngIf="condensateBottle?.visible">
                <!-- radio-option-title start -->
                <div class="radio-option-title">Condensate bottle</div>
                <!-- radio-option-title end -->

                <!-- checkbox-container start -->
                <div class="checkbox-container">
                  <mat-checkbox [ngClass]="{'custom-checkbox-color': condensateBottle.selectedValue}" class="checkbox"
                    [color]="'primary'" [checked]="condensateBottle.selectedValue"
                    (change)="onCheckboxChange(condensateBottle, $event)"
                    [disabled]="!condensateBottle.enabled"></mat-checkbox>
                </div>
                <!-- checkbox-container end -->

              </div>
              <!-- checkbox-option-container end -->

            </div>
            <!-- column end -->
          </div>
          <!-- row end -->

          <!-- row start -->
          <div class="row">

            <!-- thermal-insulation-container start -->
            <div class="thermal-insulation-container">
              <!-- thermal-insulation-title start -->
              <div class="thermal-insulation-title">
                Thermal Insulation
              </div>
              <!-- thermal-insulation-title end -->

              <!-- thermal-insulation-select-container start -->
              <div class="thermal-insulation-select-container">
                <!-- select start -->
                <select class="select small" name="insulationThickness" id="insulationThickness"
                  *ngIf="insulationThickness?.visible" [ngModel]="insulationThickness.selectedValue"
                  (ngModelChange)="onSelectChange(insulationThickness, $event)"
                  [disabled]="!insulationThickness.enabled">
                  <option *ngFor="let option of insulationThickness.options" [ngValue]="option.id">{{option.name}}
                  </option>
                </select>
                <!-- select end -->
                <!-- select start -->
                <select class="select" name="insulationMaterial" id="insulationMaterial"
                  *ngIf="insulationMaterial?.visible" [ngModel]="insulationMaterial.selectedValue"
                  (ngModelChange)="onSelectChange(insulationMaterial, $event)" [disabled]="!insulationMaterial.enabled">
                  <option *ngFor="let option of insulationMaterial.options" [ngValue]="option.id">{{option.name}}
                  </option>
                </select>
                <!-- select end -->
                <!-- select start -->
                <select class="select" name="insulationCover" id="insulationCover" *ngIf="insulationCover?.visible"
                  [ngModel]="insulationCover.selectedValue" (ngModelChange)="onSelectChange(insulationCover, $event)"
                  [disabled]="!insulationCover.enabled">
                  <option *ngFor="let option of insulationCover.options" [ngValue]="option.id">{{option.name}}</option>
                </select>
                <!-- select end -->
              </div>
              <!-- thermal-insulation-select-container end -->
            </div>
            <!-- thermal-insulation-container end -->

          </div>
          <!-- row end -->

          <!-- row start -->
          <div class="row" *ngIf="headType && headType.visible">
            <!-- head-type-container start -->
            <div class="head-type-container">

              <!-- head-type start -->
              <div class="head-type" *ngFor="let item of headType.options">

                <!-- radio-column start -->
                <div class="radio-column">
                  <input type="radio" name="headtype" id="headtype_{{item.id.toLowerCase()}}" value="{{item.id}}"
                    [ngModel]="headType.selectedValue" (ngModelChange)="onSelectChange(headType, $event)">
                  <label for="headtype_{{item.id.toLowerCase()}}">
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->
                  </label>
                </div>
                <!-- radio-column end -->

                <!-- image-column start -->
                <div class="image-column">

                  <!-- image start -->
                  <img src="{{item.image || ''}}" alt="{{item.name}}">
                  <!-- image end -->

                  <!-- text start -->
                  <div class="text">{{item.name}}</div>
                  <!-- text end -->

                </div>
                <!-- image-column end -->

              </div>
              <!-- head-type end -->

            </div>
            <!-- head-type-container end -->
          </div>
          <!-- row end -->


        </div>
        <!-- options-rows end -->


      </div>
      <!-- options-container end -->

    </div>
    <!-- solution end -->

    <!-- table-container start -->
    <div class="table-container" *ngIf="solution">
      <!-- table start -->
      <div class="table">
        <!-- table-header start -->
        <div class="table-header">
          <!-- item start -->
          <div class="item">
            <img src="../../../assets/images/size_icon.png" alt="size_icon.png">
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item">
            <img src="../../../assets/images/weight_icon.png" alt="weight_icon.png">
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item">
            <img src="../../../assets/images/area_icon.png" alt="area_icon.png">
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item">
            <img src="../../../assets/images/pressure_icon.png" alt="tube_pressure_icon.png">
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item">
            <img src="../../../assets/images/pressure_icon.png" alt="shell_pressure_icon.png">
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item">
            <img src="../../../assets/images/delivery_icon.png" alt="delivery_time_icon.png">
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item"></div>
          <!-- item end -->
        </div>
        <!-- table-header end -->

        <!-- table-body start -->
        <div class="table-body">
          <!-- item-image start -->
          <div class="item-image" (click)="view360Modal = true;">
            <img src="{{tableParameters.imageSrc || ''}}">
          </div>
          <!-- item-image end -->
          <!-- item start -->
          <div class="item">
            <div>L {{tableParameters.length}} {{physicalPropsUserUnitSystemData.length.unitSign}}
            </div>
            <div>W {{tableParameters.width}} {{physicalPropsUserUnitSystemData.length.unitSign}}
            </div>
            <div>H {{tableParameters.height}} {{physicalPropsUserUnitSystemData.length.unitSign}}
            </div>
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item">
            <div>{{tableParameters.weight}} {{physicalPropsUserUnitSystemData.weight.unitSign}}
            </div>
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item">
            <div>{{tableParameters.excessArea}} %</div>
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item"
            [ngClass]="{'red': solution.solutionData.mechanical_data.HotSideOnShell,'blue': !solution.solutionData.mechanical_data.HotSideOnShell}">
            <div *ngIf="tableParameters.shellSidePressureDrop||tableParameters.shellSidePressureDrop=== 0">
              {{tableParameters.shellSidePressureDrop}}
              {{physicalPropsUserUnitSystemData.pressure_drop.unitSign}}</div>
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item"
            [ngClass]="{'red': !solution.solutionData.mechanical_data.HotSideOnShell,'blue': solution.solutionData.mechanical_data.HotSideOnShell}">
            <div *ngIf="tableParameters.tubeSidePressureDrop||tableParameters.tubeSidePressureDrop===0">
              {{tableParameters.tubeSidePressureDrop}}
              {{physicalPropsUserUnitSystemData.pressure_drop.unitSign}}</div>
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item">
            <div>{{tableParameters.deliveryTime}}</div>
          </div>
          <!-- item end -->
          <!-- item start -->
          <div class="item icons">
            <div class="icon-download-wrapper">
              <!-- icon start -->
              <img class="icon-download" src="../../../assets/icons/download_icon.png" alt="download_icon.png"
                (click)="getDatasheet()">
              <!-- icon end -->

              <!-- spenner-wrapper start -->
              <div class="spinner-wrapper" *ngIf="datasheetSpinner">
                <mat-spinner [diameter]="20"></mat-spinner>
              </div>
              <!-- spenner-wrapper end -->
            </div>

            <div class="icon-download-wrapper" *ngIf="admin">
              <!-- icon start -->
              <img class="icon-download" src="../../../assets/icons/download_icon_internal.png" alt="download_icon.png"
                (click)="getDatasheetInternal()">
              <!-- icon end -->

              <!-- spenner-wrapper start -->
              <div class="spinner-wrapper" *ngIf="datasheetSpinnerInternal">
                <mat-spinner [diameter]="20"></mat-spinner>
              </div>
              <!-- spenner-wrapper end -->
            </div>

            <div class="icon-3d-wrapper">
              <!-- icon start -->
              <img class="icon-3d" src="../../../assets/icons/3d_icon.png" alt="3d_icon.png">
              <!-- icon end -->
            </div>

          </div>
          <!-- item end -->
        </div>
        <!-- table-body end -->

      </div>
      <!-- table end -->
    </div>
    <!-- table-container end -->

    <!-- summary-container start -->
    <div class="summary-price-container">
      <!-- row start -->
      <div class="row text-both-sides">
        <div><b>List Price</b></div>
        <div>{{tableParameters.price}} {{userCurrency}}</div>
      </div>
      <!-- row end -->

      <!-- row start -->
      <div class="row text-both-sides" *ngIf="tableParameters.optionsPrice > 0">
        <div><b>Options</b></div>
        <div>{{tableParameters.optionsPrice}} {{userCurrency}}</div>
      </div>
      <!-- row end -->

      <!-- row start -->
      <div class="row text-both-sides" *ngIf="tableParameters.discountPercent > 0">
        <div><b>Discount</b></div>
        <div>({{tableParameters.discountPercent}}%) {{tableParameters.discountValue}} {{userCurrency}}</div>
      </div>
      <!-- row end -->

      <!-- row start -->
      <div class="row text-both-sides" *ngIf="tableParameters.netOptionsPrice > 0">
        <div><b>Notified Body</b></div>
        <div>{{tableParameters.netOptionsPrice}} {{userCurrency}}</div>
      </div>
      <!-- row end -->

      <!-- row start -->
      <div class="row text-both-sides">
        <div><b>Total</b></div>
        <div><b>{{tableParameters.totalPrice}} {{userCurrency}}</b></div>
      </div>
      <!-- row end -->
    </div>
    <!-- summary-container end -->

    <!-- navigation-btns-container start -->
    <div class="navigation-btns-container">
      <!-- confirm-btn start -->
      <a href="{{backToHomePageUrl}}" class="back-btn">My projects</a>
      <!-- confirm-btn end --> <!-- confirm-btn start -->
      <button type="button" class="next-btn" (click)="sendOrder()">Add to order</button>
      <!-- confirm-btn end -->
    </div>
    <!-- navigation-btns-container end -->

  </div>
  <!-- main-container end -->
</div>
<!-- solution-comp end -->

<!-- footer start -->
<app-footer></app-footer>
<!-- footer end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="9"></app-zilo-hints>
<!-- zilo-hints end -->

<!-- heat exchanger 360 view start -->
<app-heat-exchanger-view [modelId]="solution.solutionData.mechanical_data.ID" *ngIf="view360Modal"
  (close)="view360Modal = false;">
</app-heat-exchanger-view>
<!-- heat exchanger 360 view end -->