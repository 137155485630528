<!-- header start -->
<app-header>
  <app-usermenu></app-usermenu>
</app-header>
<!-- header end -->

<!-- options component start -->
<div class="options-comp">
  <!-- main-container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">Back</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="8"></app-progressbar>
    <!-- progressbar end -->

    <!-- main title start -->
    <div class="main-title">
      Your Results
    </div>
    <!-- main title end -->

  </div>
  <!-- main-container end -->

  <!-- options-section start -->
  <div class="options-section">
    <!-- main-container start -->
    <div class="main-container">
      <!-- no results message start -->
      <div class="status-message" *ngIf="noResultsFlag">Results expired. Return back to the duty page to get results
      </div>
      <!-- no results message end -->

      <!-- no results message start -->
      <div class="status-message" *ngIf="changeRequirements">No solutions found. Adjust your requirements and try again
      </div>
      <!-- no results message end -->

      <!-- search in progress start -->
      <div class="status-message" *ngIf="searchInProgressFlag">Searching...</div>
      <!-- search in progress end -->

      <!-- options container start -->
      <div class="options-container-new" *ngIf="groups">

        <!-- options-head-icons start -->
        <div class="options-head-icons">
          <!-- option-icon start -->
          <div class="option-icon" (click)="sortBy('size')">
            <img class="dimensions" src="../../../assets/icons/option_dimensions_icon.png" alt="">
          </div>
          <!-- option-icon end -->
          <!-- option-icon start -->
          <div class="option-icon" (click)="sortBy('weight')">
            <img class="weight" src="../../../assets/icons/option_weight_icon.png" alt="">
          </div>
          <!-- option-icon end -->
          <!-- option-icon start -->
          <div class="option-icon" (click)="sortBy('excessArea')">
            <img class="excess-area" src="../../../assets/icons/option_excess_area_icon.png" alt="">
          </div>
          <!-- option-icon end -->
          <!-- option-icon start -->
          <div class="option-icon" (click)="sortBy('shellPressure')">
            <img class="pressure" src="../../../assets/icons/option_pressure_icon.png" alt="">
          </div>
          <!-- option-icon end -->
          <!-- option-icon start -->
          <div class="option-icon" (click)="sortBy('tubePressure')">
            <img class="pressure" src="../../../assets/icons/option_pressure_icon.png" alt="">
          </div>
          <!-- option-icon end -->
          <!-- option-icon start -->
          <div class="option-icon" (click)="sortBy('time')">
            <img class="delivery" src="../../../assets/icons/option_delivery_icon.png" alt="">
          </div>
          <!-- option-icon end -->
          <!-- option-icon start -->
          <div class="option-icon " (click)="sortBy('price')">
            <img class="price" src="../../../assets/icons/option_price_icon.png" alt="">
          </div>
          <!-- option-icon end -->
        </div>
        <!-- options-head-icons end -->

        <!-- option-group start -->
        <div class="option-group" *ngFor="let group of groups" [ngClass]="{'opened': group.showAllOptions}">

          <!-- main option row start -->
          <div class="option" (click)="onOptionClick(group.mainOption)">
            <!-- option-id start -->
            <div class="option-id-block" [ngClass]="{'center': group.options.length===0}">
              <div class="option-id"><span>{{group.mainOption.parsedModelName[0]}}</span> <span
                  class="bold">{{group.mainOption.parsedModelName[1]}}</span></div>
              <div class="recommend" *ngIf="group.mainOption.recommend">We recommend</div>

              <div class="option-more-btn" (click)="group.showAllOptions = true; $event.stopPropagation()"
                *ngIf="!group.showAllOptions && group.options.length>0">+ more
              </div>
              <div class="option-more-btn" (click)="group.showAllOptions = false; $event.stopPropagation()"
                *ngIf="group.showAllOptions && group.options.length>0">- less
              </div>
            </div>
            <!-- option-id end -->

            <!-- option-image-block start -->
            <div class="option-image-block" (click)="open360ViewModal($event, group.mainOption);">
              <img src="{{images[group.mainOption.modelId] || ''}}" alt="">
            </div>
            <!-- option-image-block end -->

            <!-- option-detailes start -->
            <div class="option-details">

              <!-- option-parameter Width/Height start -->
              <div class="option-parameter">
                L {{group.mainOption.length}} {{physicalPropsUserUnitSystemData.length.unitSign}}<br />
                B {{group.mainOption.width}} {{physicalPropsUserUnitSystemData.length.unitSign}}<br />
                H {{group.mainOption.height}} {{physicalPropsUserUnitSystemData.length.unitSign}}
              </div>
              <!-- option-parameter Width/Height end -->

              <!-- option-parameter Weight start -->
              <div class="option-parameter">
                {{group.mainOption.weight}} {{physicalPropsUserUnitSystemData.weight.unitSign}}
              </div>
              <!-- option-parameter Weight end -->

              <!-- option-parameter EscessArea start -->
              <div class="option-parameter">
                {{group.mainOption.excessArea}} %
              </div>
              <!-- option-parameter EscessArea end -->

              <!-- option-parameter PressureDropShellSide start -->
              <div class="option-parameter"
                [ngClass]="{'red': group.mainOption.hotSideOnShell,'blue': !group.mainOption.hotSideOnShell}">
                <ng-container *ngIf="group.mainOption.pressureDropShellSide">
                  {{group.mainOption.pressureDropShellSide}} {{physicalPropsUserUnitSystemData.pressure_drop.unitSign}}
                </ng-container>

              </div>
              <!-- option-parameter PressureDropShellSide end -->

              <!-- option-parameter PressureDropTubeSide start -->
              <div class="option-parameter"
                [ngClass]="{'red': !group.mainOption.hotSideOnShell,'blue': group.mainOption.hotSideOnShell}">
                <ng-container *ngIf="group.mainOption.pressureDropTubeSide">
                  {{group.mainOption.pressureDropTubeSide}} {{physicalPropsUserUnitSystemData.pressure_drop.unitSign}}
                </ng-container>
              </div>
              <!-- option-parameter PressureDropTubeSide end -->

              <!-- option-parameter Delivaery Time start -->
              <div class="option-parameter">
                {{group.mainOption.deliveryTime}}
              </div>
              <!-- option-parameter Delivaery Time end -->

              <!-- option-parameter Price start -->
              <div class="option-parameter" [ngClass]="{'green':group.mainOption.cheapest}">
                {{group.mainOption.price}} {{userCurrency}}
              </div>
              <!-- option-parameter Price end -->

            </div>
            <!-- option-detailes end -->

          </div>
          <!-- main option row end -->

          <!-- other options start -->
          <div *ngIf="group.options.length>0 && group.showAllOptions">
            <!-- options start -->
            <div class="option other-option" *ngFor="let option of group.options" (click)="onOptionClick(option)">
              <!-- option-id start -->
              <div class="option-id-block">
                <div class="option-id">
                  <span>{{option.parsedModelName[0]}}</span>
                  <span class="bold">{{option.parsedModelName[1]}}</span>
                </div>
                <div class="recommend" [ngClass]="{'invisible': !option.recommend}">We recommend</div>
              </div>
              <!-- option-id end -->

              <!-- option-detailes start -->
              <div class="option-details">

                <!-- option-parameter Width/Height start -->
                <div class="option-parameter">
                  L {{option.length}} {{physicalPropsUserUnitSystemData.length.unitSign}}<br />
                  B {{option.width}} {{physicalPropsUserUnitSystemData.length.unitSign}}<br />
                  H {{option.height}} {{physicalPropsUserUnitSystemData.length.unitSign}}
                </div>
                <!-- option-parameter Width/Height end -->

                <!-- option-parameter Weight start -->
                <div class="option-parameter">
                  {{option.weight}} {{physicalPropsUserUnitSystemData.weight.unitSign}}
                </div>
                <!-- option-parameter Weight end -->

                <!-- option-parameter EscessArea start -->
                <div class="option-parameter">
                  {{option.excessArea}} %
                </div>
                <!-- option-parameter EscessArea end -->

                <!-- option-parameter PressureDropShellSide start -->
                <div class="option-parameter" [ngClass]="{'red': option.hotSideOnShell,'blue': !option.hotSideOnShell}">
                  <ng-container *ngIf="option.pressureDropShellSide">
                    {{option.pressureDropShellSide}} {{physicalPropsUserUnitSystemData.pressure_drop.unitSign}}
                  </ng-container>
                </div>
                <!-- option-parameter PressureDropShellSide end -->

                <!-- option-parameter PressureDropTubeSide start -->
                <div class="option-parameter" [ngClass]="{'red': !option.hotSideOnShell,'blue': option.hotSideOnShell}">
                  <ng-container *ngIf="option.pressureDropTubeSide">
                    {{option.pressureDropTubeSide}} {{physicalPropsUserUnitSystemData.pressure_drop.unitSign}}
                  </ng-container>
                </div>
                <!-- option-parameter PressureDropTubeSide end -->

                <!-- option-parameter Delivaery Time start -->
                <div class="option-parameter">
                  {{option.deliveryTime}}
                </div>
                <!-- option-parameter Delivaery Time end -->

                <!-- option-parameter Price start -->
                <div class="option-parameter">
                  {{option.price}} {{userCurrency}}
                </div>
                <!-- option-parameter Price end -->

              </div>
              <!-- option-detailes end -->

            </div>
            <!-- options end -->
          </div>
          <!-- other options end -->
        </div>
        <!-- option-group end -->

      </div>
      <!-- options container end -->
    </div>
    <!-- main-container end -->
  </div>
  <!-- options-section end -->

</div>
<!-- options component end -->

<!-- footer start -->
<app-footer></app-footer>
<!-- footer end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="8"></app-zilo-hints>
<!-- zilo-hints end -->

<!-- heat exchanger 360 view start -->
<app-heat-exchanger-view [modelId]="modelIdFor360View" *ngIf="view360Modal" (close)="view360Modal = false;">
</app-heat-exchanger-view>
<!-- heat exchanger 360 view end -->