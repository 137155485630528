import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService, StateService } from '../../services';
import { IRegistrationCredentials } from 'src/app/services/interfaces';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent {


  public formCredentials: IRegistrationCredentials = {
    name: null,
    surname: null,
    mail: null,
    phone: null,
    dateBirthday: null,
    jobPosition: null,
    securityData: null,
    companyName: null,
    vatNumber: null,
    contactDetails: null,
    discount: null,
    market: null,
    webAddress: null,
    idLang: null
  };

  public apiErrorMessage: string = null;
  public allFieldsRequired: boolean = false;
  public registrationSuccessful: boolean = false;
  public confirmPassword: string = null;
  public passwordNotValid: boolean = null;
  public passwordsNotEqual: boolean = null;
  public emailNotValid: boolean = null;
  public paswordRegexp: RegExp = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*.]).{8,}$/gm;
  public emailRegexp: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/gm;

  constructor(private authService: AuthService, private stateService: StateService, private router: Router) {
    // empty
  }

  /**
   * Method to submit registration form.
   * @param form
   */
  public submit(form: NgForm) {
    this.resetFlags();

    // Get language selected on device.
    // Use English as fallback if deviceLang will be empty
    this.formCredentials.idLang = this.stateService.restore('deviceLang') || 'en';

    if (!this.allFormFieldsFilled()) {
      this.allFieldsRequired = true;
    }

    if (this.formCredentials.securityData && this.confirmPassword) {
      this.passwordsNotEqual = this.formCredentials.securityData !== this.confirmPassword;
    }

    if (this.formCredentials.securityData) {
      this.passwordNotValid = !this.formCredentials.securityData.match(this.paswordRegexp);
    }

    if (this.formCredentials.mail) {
      this.emailNotValid = !this.formCredentials.mail.match(this.emailRegexp);
    }

    if (form.valid) {
      this.authService.registration(this.formCredentials).subscribe({
        next: () => {
          this.registrationSuccessful = true;
        },
        error: (error) => {
          if (typeof error.error === "object") {
            let message = '';
            Object.keys(error.error.errors).forEach((_key: string) => {

              error.error.errors[_key].forEach((_str) => {
                message = `${message} ${_str}`;
              })

            });
            this.apiErrorMessage = message;

          } else {
            this.apiErrorMessage = error.error;
          }

        }
      });
    }

  };

  /**
   * Method to navigate to login page, by click on `cancel button`.
   */
  public cancel(): void {
    this.router.navigate(["/login"]);
  }

  private resetFlags(): void {
    this.allFieldsRequired = false;
    this.passwordsNotEqual = null;
    this.passwordNotValid = null;
    this.emailNotValid = null;
  }

  /**
   * Method to check that all fields have values.
   */
  private allFormFieldsFilled(): boolean {

    const relevantFields = Object.entries(this.formCredentials)
      .filter(([key, _]) => key !== 'discount')
      .map(([_, value]) => value);

    const allObjectFieldsFilled: boolean = relevantFields.every(item => item !== null && item !== undefined);

    return allObjectFieldsFilled && !!this.confirmPassword;


  }
}

