import { Component, OnInit } from '@angular/core';
import { StateService } from '../../services';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-language-menu',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageMenuComponent implements OnInit {

  public languages: string[] = ['de', 'en'];
  public currentLang: string = 'en';
  public openMenu: boolean = false;

  constructor(private stateService: StateService, private languageService: LanguageService) {
    // empty
  }

  public ngOnInit() {

    const lang = this.languageService.applyAvailableLanguage();

    if (lang) {
      this.currentLang = lang;
    }

  }

  public switchMenu(): void {
    this.openMenu = !this.openMenu;
  }

  /**
   * Method to select language on click
   * @param lang 
   */
  public selectLanguage(lang: string): void {
    this.selectLang(lang);
    this.switchMenu();
  }
  /**
   * Method to select language
   * @param lang 
   */
  private selectLang(lang: string): void {
    this.currentLang = lang;

    // Save language on user device.
    this.stateService.store('deviceLang', this.currentLang, true);

    this.languageService.useLang(this.currentLang);
  }

};