import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { forkJoin, of, switchMap } from 'rxjs';
import { ApiService, StateService, SnackBarService, } from 'src/app/services';

import { ActivatedRoute, Router } from '@angular/router';
import { INewRequirementsResponse, Role } from 'src/app/services/interfaces';
import { ILiquid, ICondensation, TParameter, IEvaporation, TPhysicalUnitsArray } from '../duty/applications';
import { IMaterial, IMaterialDefault } from '../duty/duty.component';

@Component({
  selector: 'app-extra-duty',
  templateUrl: './extra-duty.component.html',
  styleUrls: ['../duty/duty.component.scss', './extra-duty.component.scss']
})
export class ExtraDutyComponent {
  private quotationId: number = null;
  private itemId: number = null;
  private solutionId: number = null;

  public physicalPropsList: string[] = ['heat_exchange_rate'];
  public unitSystems: TPhysicalUnitsArray = null;

  /**
   * Default unit systems for each `physical property`
   */
  public defaultUnitSystems: { [key: string]: number } = {};

  public requirementApplication: INewRequirementsResponse = null;
  public existingRequirement: INewRequirementsResponse = null;
  public existingRequirementForWarmSide = null;
  public existingRequirementForColdSide = null;

  public parametersEntered: { warm: any; cold: any; } = {
    warm: null,
    cold: null
  };
  public parametersCalculated: { warm: any; cold: any; } = {
    warm: null,
    cold: null
  };

  /** Heat Exchange Rate */
  public heatExchangeRate: TParameter = {
    value: {
      userUnits: null,
      commonUnits: null
    },
    entered: false,
    calculated: false,
    unitSystemId: null
  };
  /**Minimum overdesign. `Default value 10.` */
  public overdesign: number = 10;
  public dutyName: string = null;

  public enteredParamsValidated: { valid: boolean } = { valid: false };
  public calculatedParamsValidated: { valid: boolean } = { valid: false };

  // Duty Name PopUp
  public dutyNamePopUpFlag: boolean = false;

  constructor(private apiService: ApiService, private stateService: StateService, private route: ActivatedRoute, private router: Router, private snackBarService: SnackBarService, private cd: ChangeDetectorRef) { }

  public ngOnInit(): void {
    // Get quotation ID and item ID
    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];
    this.solutionId = +this.route.snapshot.params['solutionId'];
    this.getInitialDutyData();
  };

  public back() {
    const user = this.stateService.get('user');
    let baseRole: string = null;
    // Find base role of user
    if (user.roles.includes(Role.UserAdmin)) {
      baseRole = "useradmin";
    } else if (user.roles.includes(Role.User)) {
      baseRole = "user";
    };
    this.router.navigate([`${baseRole}/configurator`, this.quotationId, this.itemId, 'solution', this.solutionId])
  };

  /** Save requirement on button click */
  public openDutyNamePopUp(): void {

    if (!this.calculatedParamsValidated.valid || !this.enteredParamsValidated.valid) {
      this.snackBarService.open('Not valid data. Change it to continue.');
      return;
    };
    this.dutyNamePopUpFlag = true;
  };
  /** Save requirement on button click */
  public saveRequest(): void {

    if (!this.dutyName) {
      this.snackBarService.open('Enter duty name to confirm.');
      return;
    };

    this.apiService.saveItemRequirements(this.itemId, this.prepareRequirementDataForSaving()).pipe(
      switchMap(() => {
        return this.apiService.startExtraDutyCalculation(this.solutionId);
      })
    ).subscribe({
      next: () => {
        this.dutyNamePopUpFlag = false;

        const user = this.stateService.get('user');
        let baseRole: string = null;

        // Find base role of user
        if (user.roles.includes(Role.UserAdmin)) {
          baseRole = "useradmin";
        } else if (user.roles.includes(Role.User)) {
          baseRole = "user";
        };
        this.router.navigate([`${baseRole}/configurator`, this.quotationId, this.itemId, 'solution', this.solutionId])

      },
      error: (error) => {
        this.dutyNamePopUpFlag = false;
        this.snackBarService.open();
      }
    });
  };

  /**Method to save value for heatExchangeRate or overdesogn params */
  public onHEorOverdesignEnter(paramName: string, value: number): void {
    const systemId: number = this.heatExchangeRate.unitSystemId;

    if (paramName === 'heatExchangeRate') {
      if (!!value || value === 0) {
        this.heatExchangeRate = {
          value: {
            userUnits: value,
            commonUnits: this.convertToSI(value, 'heat_exchange_rate', systemId)
          },
          entered: true,
          calculated: false,
          unitSystemId: systemId
        };
      } else {
        this.heatExchangeRate = {
          value: {
            userUnits: null,
            commonUnits: null
          },
          entered: false,
          calculated: false,
          unitSystemId: systemId
        };
      }
    };
    if (paramName === 'overdesign') {
      this.overdesign = !!value ? value : null;
    };
  };

  public onUnitChange(systemId: number): void {

    this.heatExchangeRate.unitSystemId = systemId;
    if (this.heatExchangeRate.value.commonUnits) {
      this.heatExchangeRate.value.userUnits = Number(this.convertToUser(this.heatExchangeRate.value.commonUnits, 'heat_exchange_rate', this.heatExchangeRate.unitSystemId).toFixed(1));
    };

  };

  /**Method to save params for warm/cold sides when they change. */
  public onSideParametersEntered(side: string, params: any) {

    if (side === 'warm') {
      this.parametersEntered = {
        warm: JSON.parse(JSON.stringify(params)),
        cold: JSON.parse(JSON.stringify(this.parametersEntered.cold))
      };
    } else {
      this.parametersEntered = {
        warm: JSON.parse(JSON.stringify(this.parametersEntered.warm)),
        cold: JSON.parse(JSON.stringify(params))
      };
    };
  };

  public onSideParametersCalculated(side: 'warm' | 'cold', params: any) {

    if (side === 'warm') {
      this.parametersCalculated = {
        warm: JSON.parse(JSON.stringify(params)),
        cold: JSON.parse(JSON.stringify(this.parametersCalculated.cold)),
      }
    } else {
      this.parametersCalculated = {
        warm: JSON.parse(JSON.stringify(this.parametersCalculated.warm)),
        cold: JSON.parse(JSON.stringify(params)),
      }
    };

  };

  public onEnteredParamsValidation(value: { valid: boolean }) {
    this.enteredParamsValidated = value;
    this.calculateHeatExchangeRate();
  };

  public onCalculatedParamsValidation(value: { valid: boolean }) {
    this.calculatedParamsValidated = value;
  };

  public calculateHeatExchangeRate() {
    // Dont calculate heatExchangeRate if it entered by user
    if (this.heatExchangeRate.entered) {
      return null;
    };

    const appWarmType: string = this.requirementApplication.requirementValue.warm[0];
    const appColdType: string = this.requirementApplication.requirementValue.cold[0];

    const warmParams = this.parametersEntered.warm;
    const coldParams = this.parametersEntered.cold;

    let warmAllParametersEntered: boolean = null;
    let coldAllParametersEntered: boolean = null;

    let result: number = null;

    if (appWarmType === 'LI' || appWarmType === 'GA') {
      warmAllParametersEntered = [warmParams?.inletTemp, warmParams?.outletTemp, warmParams?.massFlowRate].every((_x) => _x?.entered);
    } else if (appWarmType === 'CO') {
      warmAllParametersEntered = [warmParams?.satTemp, warmParams?.outletTemp, warmParams?.massFlowRate].every((_x) => _x?.entered);
    };

    if (appColdType === 'LI' || appColdType === 'GA') {
      coldAllParametersEntered = [coldParams?.inletTemp, coldParams?.outletTemp, coldParams?.massFlowRate].every((_x) => _x?.entered);
    } else if (appColdType === 'EV') {
      coldAllParametersEntered = [coldParams?.inletTemp, coldParams?.satTemp, coldParams?.massFlowRate].every((_x) => _x?.entered);
    };

    // If both sides dont have parameters, exit.
    if (!warmAllParametersEntered && !coldAllParametersEntered) {
      return null;
    };

    // Calculate HeatExchangeRate
    if (warmAllParametersEntered && !!warmParams.fluidProps) {
      if (appWarmType === 'LI' || appWarmType === 'GA') {
        result = this.calcHeatExchangeRateByLiquid('warm', warmParams);
      } else if (appWarmType === 'CO') {
        result = this.calcHeatExchangeRateByCondensation(warmParams);
      };
    } else if (coldAllParametersEntered && !!coldParams.fluidProps) {
      if (appColdType === 'LI' || appColdType === 'GA') {
        result = this.calcHeatExchangeRateByLiquid('cold', coldParams);
      } else if (appColdType === 'EV') {
        result = this.calcHeatExchangeRateByEvaporation(coldParams);
      };
    };

    this.heatExchangeRate.value.commonUnits = result;
    this.heatExchangeRate.value.userUnits = this.convertToUser(result, 'heat_exchange_rate', this.heatExchangeRate.unitSystemId);
    this.heatExchangeRate.value.userUnits = Number(this.heatExchangeRate.value.userUnits.toFixed(1));

    this.heatExchangeRate.entered = false;
    this.heatExchangeRate.calculated = true;
  };

  private calcHeatExchangeRateByLiquid(side: 'warm' | 'cold', params: ILiquid): number {

    let fluidProps = params.fluidProps['specific_heat_cap'];

    let massFlowRate: number = params.massFlowRate.value.commonUnits;
    let inletTemp: number = params.inletTemp.value.commonUnits;
    let outletTemp: number = params.outletTemp.value.commonUnits;

    let inletTempSHC: number = this.findTableValueByTemp(inletTemp, fluidProps);
    let outletTempSHC: number = this.findTableValueByTemp(outletTemp, fluidProps);

    let specificHeatCapacityRange = null;
    let specificHeatCapacity = null;

    let temperatures = [outletTemp, inletTemp].sort((a, b) => a - b);
    specificHeatCapacityRange = fluidProps.filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
    specificHeatCapacityRange = [...specificHeatCapacityRange, inletTempSHC, outletTempSHC];

    // Calculate avarage value
    specificHeatCapacity = specificHeatCapacityRange.reduce((acc, next) => acc + next, 0) / specificHeatCapacityRange.length;

    if (side === 'warm') {
      return massFlowRate * specificHeatCapacity * (inletTemp - outletTemp);
    } else if (side === 'cold') {
      return massFlowRate * specificHeatCapacity * (outletTemp - inletTemp);
    };

  };

  private calcHeatExchangeRateByCondensation(params: ICondensation): number {

    const massFlowRate: number = params.massFlowRate.value.commonUnits;
    const satTemp: number = params.satTemp.value.commonUnits;
    const outletTemp: number = params.outletTemp.value.commonUnits;

    const enthalpyVapor: number = this.findTableValueByTemp(satTemp, params.fluidProps['enthalpy_vapor']);
    const enthalpyLiquid: number = this.findTableValueByTemp(satTemp, params.fluidProps['enthalpy_liquid']);

    const satTempSHC: number = this.findTableValueByTemp(satTemp, params.fluidProps['specific_heat_cap']);
    const outletTempSHC: number = this.findTableValueByTemp(outletTemp, params.fluidProps['specific_heat_cap']);

    let specificHeatCapacityRange: number[] = null;
    let specificHeatCapacity: number = null;

    let temperatures = [satTemp, outletTemp].sort((a, b) => a - b);
    specificHeatCapacityRange = params.fluidProps['specific_heat_cap'].filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
    specificHeatCapacityRange = [...specificHeatCapacityRange, satTempSHC, outletTempSHC];

    // Calculate avarage value
    specificHeatCapacity = specificHeatCapacityRange.reduce((acc, next) => acc + next, 0) / specificHeatCapacityRange.length;

    return massFlowRate * specificHeatCapacity * (satTemp - outletTemp) + massFlowRate * (enthalpyVapor - enthalpyLiquid);
  };

  private calcHeatExchangeRateByEvaporation(params: IEvaporation): number {

    const massFlowRate: number = params.massFlowRate.value.commonUnits;
    const satTemp: number = params.satTemp.value.commonUnits;
    const inletTemp: number = params.inletTemp.value.commonUnits;

    const enthalpyVapor: number = this.findTableValueByTemp(satTemp, params.fluidProps['enthalpy_vapor']);
    const enthalpyLiquid: number = this.findTableValueByTemp(satTemp, params.fluidProps['enthalpy_liquid']);

    const inletTempSHC: number = this.findTableValueByTemp(inletTemp, params.fluidProps['specific_heat_cap']);
    const satTempSHC: number = this.findTableValueByTemp(satTemp, params.fluidProps['specific_heat_cap']);

    let specificHeatCapacityRange: number[] = null;
    let specificHeatCapacity: number = null;

    let temperatures = [satTemp, inletTemp].sort((a, b) => a - b);
    specificHeatCapacityRange = params.fluidProps['specific_heat_cap'].filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
    specificHeatCapacityRange = [...specificHeatCapacityRange, satTempSHC, inletTempSHC];

    // Calculate avarage value
    specificHeatCapacity = specificHeatCapacityRange.reduce((acc, next) => acc + next, 0) / specificHeatCapacityRange.length;

    return massFlowRate * specificHeatCapacity * (satTemp - inletTemp) + massFlowRate * (enthalpyVapor - enthalpyLiquid);
  };

  /** Methos to find property value by temperature */
  private findTableValueByTemp(temperature: number, fluidProps: any[]): number {

    // List of all temperatures in table
    const allTableTemp = fluidProps.map((_x) => _x.dependency1Value);
    // Table min temperature
    const tableMinTemp = Math.min.apply(Math, allTableTemp);
    // Table max temperature
    const tableMaxTemp = Math.max.apply(Math, allTableTemp);


    let temperatureTableRecord = fluidProps.find((_x) => _x.dependency1Value === temperature);
    let result = null;

    // 1. If record exist, take value.
    // 2. If record was no found in step 1, check if entered value is in RANGE of table temperatures.
    //    If true, find its value with linear interpolation.
    // 3. If value was not found in previous steps, check if entered value is out of range of table temperatures.
    //    If true, find closest temperature and take its value.

    if (!!temperatureTableRecord) {
      result = temperatureTableRecord.propertyValue;
    } else if (temperature >= tableMinTemp && temperature <= tableMaxTemp) {
      // Find closest temperatures for entered value
      const closestMinTemp = this.findClosestNumberSmaller(temperature, allTableTemp);
      const closestMaxTemp = this.findClosestNumberBigger(temperature, allTableTemp);

      const closestMinTempPressure = fluidProps.find((_x) => _x.dependency1Value === closestMinTemp).propertyValue;
      const closestMaxTempPressure = fluidProps.find((_x) => _x.dependency1Value === closestMaxTemp).propertyValue;

      result = closestMinTempPressure + (temperature - closestMinTemp) * (closestMaxTempPressure - closestMinTempPressure) / (closestMaxTemp - closestMinTemp);
    } else if (temperature > tableMaxTemp || temperature < tableMinTemp) {
      const closestTemp = this.findClosestNumber(temperature, allTableTemp);
      result = fluidProps.find((_x) => _x.dependency1Value === closestTemp).propertyValue;
    };

    return result;
  };
  private findClosestNumberSmaller(x: number, arr: number[]): number | null {
    const filteredArr = arr.filter((k) => k < x);
    const indexArr = filteredArr.map((k) => Math.abs(k - x));
    const min = Math.min(...indexArr);
    return filteredArr[indexArr.indexOf(min)];
  }

  private findClosestNumberBigger(x: number, arr: number[]): number | null {
    const filteredArr = arr.filter((k) => k > x);
    const indexArr = filteredArr.map((k) => Math.abs(k - x));
    const min = Math.min(...indexArr);
    return filteredArr[indexArr.indexOf(min)];
  }
  private findClosestNumber(x, arr) {
    const indexArr = arr.map((k) => {
      return Math.abs(k - x)
    })
    const min = Math.min.apply(Math, indexArr)
    return arr[indexArr.indexOf(min)]
  };

  /**
   * Method to convert value `from users unit system to SI`.
   * @param value
   * @param propName physical property name. See `physicalPropsList property` for correct typing
   * @returns
   */
  private convertToSI(value: number, propName: string, systemId: number): number {
    const prop = this.unitSystems[propName].find((x) => x.unitSystemId === systemId);
    if (value === 0 || !value) {
      return value;
    };

    return prop.addFactor + (value * prop.multFactor)

  };

  /**
   * Method to convert value `from SI unit system to users unit system`.
   * @param value
   * @param propName physical property name. See `physicalPropsList property` for correct typing
   * @returns
   */
  private convertToUser(value: number, propName: string, systemId: number): number {
    const prop = this.unitSystems[propName].find((x) => x.unitSystemId === systemId);
    if (value === 0 || !value) {
      return value;
    };

    return (value - prop.addFactor) / prop.multFactor;

  };

  private prepareRequirementDataForSaving() {
    // Function to prepare parameters for saving
    function prepareParams(params) {
      const result = {};
      Object.keys(params).forEach((_key: string) => {

        if (_key === 'liquid') {

          result[_key] = params[_key];
          delete result[_key].selectable;
        } else if (_key !== 'fluidProps') {
          result[_key] = {
            value: params[_key].value.commonUnits,
            entered: params[_key].entered,
            calculated: params[_key].calculated,
          };
        };

      });
      return result;
    };

    const heatExchangeRate = JSON.parse(JSON.stringify(this.heatExchangeRate));

    const preparedWarmParams = prepareParams(JSON.parse(JSON.stringify(this.parametersCalculated.warm)));
    const preparedColdParams = prepareParams(JSON.parse(JSON.stringify(this.parametersCalculated.cold)));
    const preparedHE = {
      value: heatExchangeRate.value.commonUnits,
      entered: heatExchangeRate.entered,
      calculated: heatExchangeRate.calculated,
    };

    // Create deep copy of parameters.
    const requirement: { requirementType: string; requirementValue: string | any; } = {
      requirementType: "extra_duty",
      requirementValue: {
        warm: preparedWarmParams,
        cold: preparedColdParams,
        heatExchangeRate: preparedHE,
        overdesign: this.overdesign,
        application: this.requirementApplication.requirementValue,
        name: this.dutyName
      }
    };
    // requirement.requirementValue = JSON.stringify(requirement.requirementValue);
    return requirement;

  };


  /**
   * Method to load requirement data for duty page.
   */
  private getInitialDutyData() {

    const userUnitSystem: string = this.stateService.get('user').unitSystem;

    this.apiService.getAllUnitSystemData(this.physicalPropsList).pipe(switchMap((_unitSystems) => {
      this.unitSystems = _unitSystems;

      // Create default unit system for each prop
      // If list of systems have system saved in user profile, use it as default
      // if not use 1 unit system.
      this.physicalPropsList.forEach((_propName: string) => {
        this.defaultUnitSystems[_propName] = !!_unitSystems[_propName].find((_x) => _x.unitSystemId === +userUnitSystem) ? +userUnitSystem : 1;
      });

      this.heatExchangeRate.unitSystemId = this.defaultUnitSystems.heat_exchange_rate;

      return this.apiService.getItemRequirements(this.itemId);
    })).subscribe({
      next: (requirementsResp) => {
        /** Requirement data from step 2 */
        this.requirementApplication = requirementsResp.find((_x) => _x.requirementType === 'application');
        // this.requirementApplication.requirementValue = JSON.parse(this.requirementApplication.requirementValue);

      },
      error: (error) => {
        console.log(error);
        this.snackBarService.open();
      }
    });
  };

}
