import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from 'src/environments/environment';

import { StateService } from './index';
import { AuthenticatedResponse, IRegistrationCredentials, LoginModel, User } from './interfaces';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private base: string = environment.base;
  constructor(private http: HttpClient, private stateService: StateService, private jwtHelper: JwtHelperService) { }

  /** Sing in user with credentials */
  public singin(credentials: LoginModel): Observable<User[]> {
    return this.http.post<AuthenticatedResponse[]>(`${this.base}/api/auth/login`, credentials, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    })
      .pipe(
        map((response: AuthenticatedResponse[]) => {
          const users: User[] = response.map((_response) => {

            const token = _response.token;

            const decoded = this.jwtHelper.decodeToken(token);
            let roles: string[] | string = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

            if (typeof roles === 'string') {
              roles = [roles];
            };

            return Object.assign({ roles }, _response);

          });
          this.stateService.store('users', users);

          return users;

        })
      );
  }

  /**
   * Method to get new list of available users.
   * Method update current list of users.
   * @returns 
   */
  public changeUser(): Observable<User[]> {
    return this.http.get<AuthenticatedResponse[]>(`${this.base}/api/auth/changeuser`).pipe(
      map((response: AuthenticatedResponse[]) => {
        const users: User[] = response.map((_response) => {

          const token = _response.token;

          const decoded = this.jwtHelper.decodeToken(token);
          let roles: string[] | string = decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

          if (typeof roles === 'string') {
            roles = [roles];
          };

          return Object.assign({ roles }, _response);

        });
        this.stateService.store('users', users);

        return users;
      })
    )
  }

  public registration(credentials: IRegistrationCredentials): Observable<any> {
    return this.http.post(`${this.base}/api/register`, credentials, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    })
  }

  public forgotPassword(email: string) {

    const params = new HttpParams().append('email', email)

    return this.http.post(`${this.base}/api/auth/forgotpassword`, null, {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      responseType: 'text',
      params: params
    })
  }

  public resetPassword(password: string, code: string) {

    const params = new HttpParams().append('code', code)

    return this.http.post(`${this.base}/api/auth/resetpassword`, JSON.stringify({ 'newPassword': password }), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      responseType: 'text',
      params: params
    })
  }

  /** Is user token still valid ? */
  public isAuthenticated() {

    const token = localStorage.getItem("jwt");
    return token && !this.jwtHelper.isTokenExpired(token);
  }
}