<!-- registration component start -->

<ng-container>
  <!-- header start -->
  <app-header>
    <app-language-menu></app-language-menu>
  </app-header>
  <!-- header end -->

  <!-- registration section start -->
  <div class="registration-section">
    <!-- main container start -->
    <div class="main-container">
      <!-- registration form start -->
      <form #registrationForm="ngForm" class="registration-form" (submit)="submit(registrationForm)"
        *ngIf="!registrationSuccessful">
        <!-- field start -->
        <div class="field">
          <label for="name">{{'registration.form.fields.firstName' | translate}}:</label>
          <input type="text" name="name" id="name" [(ngModel)]="formCredentials.name" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="surname">{{'registration.form.fields.lastName' | translate}}:</label>
          <input type="text" name="surname" id="surname" [(ngModel)]="formCredentials.surname" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="mail">{{'registration.form.fields.email' | translate}}:</label>
          <input type="email" name="mail" id="mail" [(ngModel)]="formCredentials.mail"
            patterns="^[^\s@]+@[^\s@]+\.[^\s@]+$" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="securityData">{{'registration.form.fields.password' | translate}}:</label>
          <input type="password" name="securityData" id="securityData" [(ngModel)]="formCredentials.securityData"
            pattern="^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*.]).{8,}$" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="confirmPassword">{{'registration.form.fields.confirmPassword' | translate}}:</label>
          <input type="password" name="confirmPassword" id="confirmPassword" [(ngModel)]="confirmPassword"
            pattern="^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*.]).{8,}$" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="phone">{{'registration.form.fields.phone' | translate}}:</label>
          <input type="tel" name="phone" id="phone" [(ngModel)]="formCredentials.phone" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="dateBirthday">{{'registration.form.fields.birthday' | translate}}:</label>
          <input type="date" name="dateBirthday" id="dateBirthday" [(ngModel)]="formCredentials.dateBirthday" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="jobPosition">{{'registration.form.fields.job' | translate}}:</label>
          <input type="text" name="jobPosition" id="jobPosition" [(ngModel)]="formCredentials.jobPosition" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="companyName">{{'registration.form.fields.companyName' | translate}}:</label>
          <input type="text" name="companyName" id="companyName" [(ngModel)]="formCredentials.companyName" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="vatNumber">{{'registration.form.fields.vatNumber' | translate}}:</label>
          <input type="text" name="vatNumber" id="vatNumber" [(ngModel)]="formCredentials.vatNumber" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="market">{{'registration.form.fields.markets' | translate}}:</label>
          <input type="text" name="market" id="market" [(ngModel)]="formCredentials.market" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="webAddress">{{'registration.form.fields.webAddress' | translate}}:</label>
          <input type="text" name="webAddress" id="webAddress" [(ngModel)]="formCredentials.webAddress" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="contactDetails">{{'registration.form.fields.contactDetails' | translate}}:</label>
          <input type="text" name="contactDetails" id="contactDetails" [(ngModel)]="formCredentials.contactDetails"
            required>
        </div>
        <!-- field end -->

        <!-- invalid message start -->
        <div class="invalidMsg" *ngIf="allFieldsRequired">
          {{'registration.form.validationMsg.allFieldsRequired'|translate}}</div>
        <!-- invalid message end -->

        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="passwordsNotEqual">
          {{'registration.form.validationMsg.passwordsNotEqual'|translate}}</div>
        <!-- not equal passwords message end -->

        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="passwordNotValid">
          {{'registration.form.validationMsg.passwordNotValid1'|translate}} <br />
          {{'registration.form.validationMsg.passwordNotValid2'|translate}}</div>
        <!-- not equal passwords message end -->

        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="emailNotValid">{{'registration.form.validationMsg.emailNotValid'|translate}}
        </div>
        <!-- not equal passwords message end -->

        <!-- invalid message start -->
        <div class="invalidMsg" *ngIf="apiErrorMessage">{{apiErrorMessage}}</div>
        <!-- invalid message end -->

        <!-- buttons block start -->
        <div class="buttons-block">
          <!-- button start -->
          <button class="btn btn-cancel" (click)="cancel();">{{'registration.form.buttons.cancel' | translate}}</button>
          <!-- button end -->
          <!-- button start -->
          <button type="submit" class="btn btn-register">{{'registration.form.buttons.register' | translate}}</button>
          <!-- button end -->
        </div>
        <!-- buttons block end -->
      </form>
      <!-- registration form end -->

      <!-- registration successful message start -->
      <div class="msg-after-reg" *ngIf="registrationSuccessful">We will review your registration data and send you an
        email when the account will be
        active</div>
      <!-- registration successful message end -->

    </div>
    <!-- main container end -->
  </div>
  <!-- registration section end -->

</ng-container>

<!-- registration component end -->