import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackbar: MatSnackBar) {
    // empty
  }

  /**
   * Method to open snackbar.
   * Returns observable after dismised.
   * @param message Message to show. Default message `'Something goes wrong. Try again.'`.
   * @param duration Duration of message. Value must be in seconds. Default 3 seconds.
   */
  public open(message?: string, duration: number = 3): Observable<MatSnackBarDismiss> {
    const msg = message || "Something goes wrong. Try again.";

    return this.snackbar.open(msg, 'Close', { panelClass: 'snackbar-custom', duration: duration * 1000 }).afterDismissed();
  }
}
