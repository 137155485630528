<!-- footer component start -->
<div class="footer-comp">

  <!-- content block start -->
  <div class="content">

    <!-- chat bot start -->
    <div class="chat-bot">

      <!--popup start -->
      <div class="popup">
        <!-- title start -->
        <div class="title">Welcome back!</div>
        <!-- title end -->
        <!-- message start -->
        <div class="message">Tip: You can find help
          under the menu item XY
          To the topic...</div>
        <!-- message end -->
      </div>
      <!-- popup end -->

      <!-- bot image start -->
      <img src="../../../assets/images/zilo_assistant_window.png" alt="zilo_assistant_window.png" class="bot-image">
      <!-- bot image end -->
    </div>
    <!-- chat bot end -->

    <!-- text content start -->
    <div class="text-content">
      <!-- question start-->
      <div class="bold-text">You need help?</div>
      <!-- question end-->
      <!-- chat nad phone start -->
      <div><span class="chat-text">Chat now</span> or call us +49 2662 500 44 0</div> 
      <!-- chat nad phone end -->
    </div>
    <!-- text content end -->

  </div>
  <!-- content block end -->


</div>
<!-- footer component end -->